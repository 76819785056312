<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.xsOnly"
            style="height:100vh; margin:0 auto; box-shadow: -6px 0 4px -4px #eee, 6px 0 4px -4px #eee;"
            :style="$store.state.screen_mode == 'expanded'? 'width:880px;':'width:440px;'"
        >
            <AppHeader
                v-if="!$store.state.twin"
                style="height:48px;"
            />
            <div
                class="d-flex"
                style="flex: 1; overflow: hidden; max-height:calc(100% - 48px); height:fit-content;"
            >
                <div
                    style="width:440px; border-right:1px solid #eee;"
                >
                    <HomeHeader
                        v-if="!($store.state.twin || $route.path.startsWith('/auth'))"
                    />
                    <router-view
                        :key="$route.fullPath"
                        :style="$route.path == '/' ? 'height:100%; overflow:hidden;' : 'height:100%; overflow-y:auto; overflow-x:hidden;'"
                    ></router-view>
                </div>
                <div
                    v-if="$store.state.screen_mode == 'expanded'"
                    style="width:440px; overflow:hidden;"
                >
                    <ListHeader />
                    <ViewsList />
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.xsOnly"
            style="height:300px;"
        >
            <AppHeader
                v-if="!($store.state.twin || $route.path.startsWith('/auth'))"
            />

            <HomeHeader
                v-if="!$store.state.twin && ($route.path == '/' || $route.path == '/mypage')"
            />

            <ListHeader
                v-if="$route.path == '/list'"
            />

            <router-view
                :key="$route.fullPath"
                style="height:100%;"
            ></router-view>
        </div>
    </div>
</template>

<script>
import AppHeader from './header/AppHeader'
import HomeHeader from './header/HomeHeader'
import ListHeader from './header/ListHeader'
import ViewsList from '@/views/List'

export default {
    components: {
        AppHeader,
        HomeHeader,
        ListHeader,
        ViewsList
    },

    mounted() {
        this.updateScreenMode()
        window.addEventListener('resize', this.updateScreenMode)
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenMode)
    },

    methods: {
        updateScreenMode: _.debounce(function() {
            setTimeout(() => {
                console.log('Updating screen mode', this.$vuetify.breakpoint.height); // 디버깅용 로그 추가
                this.$store.commit('update_screen_mode', this.$vuetify.breakpoint.width > 880 ? 'expanded' : 'shrink');
                this.$store.commit('update_keypad_mode', this.$vuetify.breakpoint.height > 640 ? 'expanded' : 'minimized');
            }, 100); // 100ms 후에 호출
        }, 200) // 200ms 디바운스
    },

    computed: {
        // getRouterViewMargin_pc() {
        //     // 로그인 상태
        //     if(this.$store.state.client_user.is_logined){
        //         // 홈
        //         if (this.$route.path === '/') {
        //             return 'margin-top:-3px !important'
        //         }

        //         // 마이페이지
        //         else if (this.$route.path === '/mypage') {
        //             return 'margin-top:4px !important'
        //         }
        //     }

        //     // 비로그인 상태
        //     else {
        //         // 홈
        //         if (this.$route.path === '/') {
        //             return 'margin-top:-13px !important'
        //         }

        //         // 마이페이지 & Auth
        //         else if (this.$route.path === '/mypage' || this.$route.path.startsWith('/auth')) {
        //             return 'margin-top:0px !important'
        //         }
        //     }
        // },

        // getRouterViewMargin_mobile() {
        //     // 로그인 상태
        //     if(this.$store.state.client_user.is_logined){
        //         // 홈
        //         if (this.$route.path === '/') {
        //             return 'margin-top:-3px !important'
        //         }

        //         // 목록
        //         else if (this.$route.path === '/list') {
        //             return 'margin-top:10px !important'
        //         }

        //         // 마이페이지
        //         else if (this.$route.path === '/mypage') {
        //             return 'margin-top:4px !important'
        //         }
        //     }

        //     // 비로그인 상태
        //     else {
        //         // 홈
        //         if (this.$route.path === '/') {
        //             return 'margin-top:-13px !important'
        //         }

        //         // 목록
        //         else if (this.$route.path === '/list') {
        //             return 'margin-top:10px !important'
        //         }

        //         // 마이페이지
        //         else if (this.$route.path === '/mypage') {
        //             return 'margin-top:4px !important'
        //         }
        //     }
        // }
    }
}
</script>