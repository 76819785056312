<template>
    <v-sheet
        class="pt-4 pb-10"
        :class="$vuetify.breakpoint.xsOnly? 'px-8':'px-10'"
        style="border-top:1px solid #eee; width:100%;"
    >
        <v-sheet
            v-if="$store.state.mypage_tab == '기본 설정'"
            class="pt-0 pb-0 mt-10 rounded-xl"
        >
            <table class="custom_table mx-auto mt-10">
                <tr>
                    <td class="pb-7">{{ $store.state.language == 'English' ? 'ID' : '아이디' }}</td>
                    <td>
                        <v-text-field
                            class="ml-2 mb-7 rounded-10"
                            style="background:#f5f5f5;"
                            outlined
                            dense
                            readonly
                            hide-details
                            :placeholder="$store.state.client_user.user_id"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td class="pb-7">{{ $store.state.language == 'English' ? 'Email' : '이메일' }}</td>
                    <td>
                        <v-text-field
                            class="ml-2 mb-7 rounded-10"
                            style="background:#f5f5f5;"
                            outlined
                            dense
                            readonly
                            hide-details
                            :placeholder="$store.state.client_user.email"
                        ></v-text-field>
                    </td>
                </tr>
                <!-- <tr>
                    <td class="pb-7">{{ $store.state.language == 'English' ? 'Kakao Agreement' : '카카오톡 동의' }}</td>
                    <td
                        class="pb-7"
                    >
                        <div
                            class="d-flex justify-center"
                        >
                            <v-switch
                                class="mt-0"
                                hide-details
                                dense
                                :label="$store.state.language == 'English' 
                                ? (marketing_kakao ? 'Agree' : 'Disagree') 
                                : (marketing_kakao ? '동의' : '거부')"
                                v-model="marketing_kakao"
                                @change="update_marketing_kakao()"
                            ></v-switch>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="pb-7">{{ $store.state.language == 'English' ? 'SMS Agreement' : 'SMS 동의' }}</td>
                    <td
                        class="pb-7"
                    >
                        <div
                            class="d-flex justify-center"
                        >
                            <v-switch
                                class="mt-0"
                                hide-details
                                dense
                                :label="$store.state.language == 'English' 
                                ? (marketing_sms ? 'Agree' : 'Disagree') 
                                : (marketing_sms ? '동의' : '거부')"
                                v-model="marketing_sms"
                                @change="update_marketing_sms()"
                            ></v-switch>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="pb-7">{{ $store.state.language == 'English' ? 'App PUSH Agreement' : '앱 PUSH 동의' }}</td>
                    <td
                        class="pb-7"
                    >
                        <div
                            class="d-flex justify-center"
                        >
                            <v-switch
                                class="mt-0"
                                hide-details
                                dense
                                :label="$store.state.language == 'English' 
                                ? (marketing_app ? 'Agree' : 'Disagree') 
                                : (marketing_app ? '동의' : '거부')"
                                v-model="marketing_app"
                                @change="update_marketing_app()"
                            ></v-switch>
                        </div>
                    </td>
                </tr> -->
                <tr>
                    <td :class="$store.state.language == 'English'? 'pb-8' : 'pb-9'" style="line-height:1.4em">{{ $store.state.language == 'English' ? 'Current Password' : '현재 비밀번호' }}<font class="ml-1 red--text">*</font></td>
                    <td style="height:85px;">
                        <v-text-field
                            class="ml-2 rounded-10"
                            style="min-height:77px;"
                            outlined
                            dense
                            persistent-placeholder
                            :type="showPassword.prev ? 'text' : 'password'"
                            :append-icon="showPassword.prev ? 'mdi-eye-off' : 'mdi-eye'"
                            v-model="password_prev"
                            @change="$v.password_prev.$touch()"
                            @blur="$v.password_prev.$touch()"
                            :error-messages="password_prev_Errors"
                            @click:append="togglePasswordVisibility('prev')"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td :class="$store.state.language == 'English'? 'pb-9' : 'pb-10'" style="line-height:1.4em">{{ $store.state.language == 'English' ? 'New Password' : '새 비밀번호' }}</td>
                    <td style="height:85px;">
                        <v-text-field
                            class="ml-2 rounded-10"
                            style="min-height:77px;"
                            outlined
                            dense
                            persistent-placeholder
                            :type="showPassword.next ? 'text' : 'password'"
                            :append-icon="showPassword.next ? 'mdi-eye-off' : 'mdi-eye'"
                            v-model="password_next"
                            @change="$v.password_next.$touch()"
                            @blur="$v.password_next.$touch()"
                            :error-messages="password_next_Errors"
                            @click:append="togglePasswordVisibility('next')"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td :class="$store.state.language == 'English'? 'pb-10' : 'pb-10'" style="line-height:1.4em">{{ $store.state.language == 'English' ? 'Confirm New Password' : '새 비밀번호 확인' }}</td>
                    <td style="height:85px;">
                        <v-text-field
                            class="ml-2 rounded-10"
                            style="min-height:77px;"
                            outlined
                            dense
                            persistent-placeholder
                            :type="showPassword.check ? 'text' : 'password'"
                            :append-icon="showPassword.check ? 'mdi-eye-off' : 'mdi-eye'"
                            v-model="password_check"
                            @change="$v.password_check.$touch()"
                            @blur="$v.password_check.$touch()"
                            :error-messages="password_check_Errors"
                            @click:append="togglePasswordVisibility('check')"
                        ></v-text-field>
                    </td>
                </tr>
            </table>

            <!-- 제출 -->
            <v-sheet class="mb-6 d-flex justify-center">
                <v-btn
                    @click="submit()"
                    class="rounded-10 mx-2 px-10"
                    large
                    dark
                    depressed
                    color="primary"
                >
                    {{ $store.state.language == 'English' ? 'Change Password' : '비밀번호 수정하기' }}
                </v-btn>
            </v-sheet>

            <v-btn
                width="120"
                class="grey--text text--darken-1 rounded-lg d-flex py-4 mx-auto"
                color="grey lighten-2"
                depressed
                small
                dark
                text
                to="/auth/logout"
            >
                {{ $store.state.language == 'English' ? 'Log out' : '로그아웃' }}
            </v-btn>

            <v-btn
                text
                class="grey--text text--darken-1 rounded-lg py-3 px-2 d-flex mt-10 ml-auto"
                depressed
                small
                @click="leaveSubmit()"
            >
                {{ $store.state.language == 'English' ? 'Delete Account &nbsp;→' : '회원탈퇴하기 &nbsp;→' }}
            </v-btn>
        </v-sheet>

        <v-sheet
            v-if="$store.state.mypage_tab == '계산 설정'"
            class="pt-0 pb-6 mt-10 rounded-xl"
        >
            <table class="custom_table mx-auto px-2 mt-10">
                <tr>
                    <td class="pb-8">
                        <p
                            class="mb-0"
                        >
                            {{ $store.state.language == 'English' ? 'Decimal Setting' : '소수점 설정' }}
                        </p>
                        <p
                            class="text-caption"
                            style="color:#ef6d6d;"
                        >
                            {{ $store.state.language == 'English' ? 'Zero cases' : '정수가 0일 경우' }}
                        </p>

                    </td>
                    <td>
                        <v-select
                            class="ml-2 mb-12 rounded-10"
                            outlined
                            dense
                            hide-details
                            :items="select_list"
                            :item-text="$store.state.language == 'English' ? 'name_en' : 'name_kr'"
                            item-value="value"
                            v-model="sosujum"
                            @change="sosujum_changed()"
                        ></v-select>
                    </td>
                </tr>
            </table>
        </v-sheet>
    </v-sheet>
</template>
<script>
// 검증
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
        password_prev: {
            required,
            minLength: minLength(8)
        },

        password_next: {
            minLength: minLength(8)
        },

        password_check: {
            sameAsPassword: sameAs('password_next')
        }
    },

    data: () => ({
        select_list: [
            { name_kr: "소수점 1자리 까지", name_en: "Up to 1 Decimals", value: 1 },
            { name_kr: "소수점 2자리 까지", name_en: "Up to 2 Decimals", value: 2 },
            { name_kr: "소수점 3자리 까지", name_en: "Up to 3 Decimals", value: 3 },
            { name_kr: "소수점 4자리 까지", name_en: "Up to 4 Decimals", value: 4 },
            { name_kr: "소수점 5자리 까지", name_en: "Up to 5 Decimals", value: 5 },
            { name_kr: "소수점 6자리 까지", name_en: "Up to 6 Decimals", value: 6 },
            { name_kr: "소수점 7자리 까지", name_en: "Up to 7 Decimals", value: 7 },
            { name_kr: "소수점 8자리 까지", name_en: "Up to 8 Decimals", value: 8 },
        ],
        sosujum: 2,
        drawer: false,

        name_list: [],

        password_prev: "",
        password_next: "",
        password_check: "",
        name: "",
        marketing_kakao: false,
        marketing_sms: false,
        marketing_app: false,

        user: {
            profile_image: "",
        },

        dialog: {
            updateImage: false
        },

        showPassword: {
            prev: false,
            next: false,
            check: false,
        },
    }),

    computed: {
        // 현재 비밀번호 Error 문구
        password_prev_Errors () {
            const errors = []
            if (!this.$v.password_prev.$dirty) return errors
            !this.$v.password_prev.required && errors.push(this.$store.state.language == 'English' ? 'Please enter your current password' : '현재 비밀번호를 입력해주세요')
            !this.$v.password_prev.minLength && errors.push(this.$store.state.language == 'English' ? 'The password must be at least 8 characters long' : '패스워드는 최소 8자 이상이여야합니다')
            return errors
        },

        // 신규 비밀번호 Error 문구
        password_next_Errors () {
            const errors = []
            if (!this.$v.password_next.$dirty) return errors
            !this.$v.password_next.minLength && errors.push(this.$store.state.language == 'English' ? 'The password must be at least 8 characters long' : '패스워드는 최소 8자 이상이여야합니다')
            return errors
        },

        // 신규 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.sameAsPassword && errors.push(this.$store.state.language == 'English' ? 'The new password confirmation does not match' : '신규 비밀번호 확인이 같지 않습니다')
            return errors
        }
    },

    mounted(){
        // 유저정보 불러오기
        this.load()

        this.sosujum = this.$store.state.sosujum
    },

    methods: {
        togglePasswordVisibility(field) {
			this.showPassword[field] = !this.showPassword[field]
		},

        sosujum_changed(){
            this.$store.commit('update_sosujum', this.sosujum)
        },

        // 유저정보 불러오기
        load(){
            this.$http.post("/api/user/select/specific", {
                params: {
                    user_id: this.$store.state.client_user.user_id
                }
            }).then((res) => {
                this.user.profile_image = res.data[0].profile_image
                this.name = res.data[0].name
                this.marketing_kakao = res.data[0].marketing_kakao
                this.marketing_sms = res.data[0].marketing_sms
                this.marketing_app = res.data[0].marketing_app
            })
        },

        // 카카오톡 동의 수정하기
        update_marketing_kakao(){
            this.$http.post("/api/user/update/marketing_kakao", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    marketing_kakao: this.marketing_kakao
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    // this.$globalSnackbar.show(`카카오톡 마케팅 수신 동의가 ${this.marketing_kakao? "동의":"거부"}처리되었습니다`)
                    this.password_prev = ""
                    this.password_next = ""
                    this.password_check = ""
                    this.load()
                }
            })
        },

        // SMS 동의 수정하기
        update_marketing_sms(){
            this.$http.post("/api/user/update/marketing_sms", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    marketing_sms: this.marketing_sms
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    // this.$globalSnackbar.show(`SMS 마케팅 수신 동의가 ${this.marketing_sms? "동의":"거부"}처리되었습니다`)
                    this.password_prev = ""
                    this.password_next = ""
                    this.password_check = ""
                    this.load()
                }
            })
        },

        // 앱 PUSH 동의 수정하기
        update_marketing_app(){
            this.$http.post("/api/user/update/marketing_app", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    marketing_app: this.marketing_app
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    // this.$globalSnackbar.show(`앱 PUSH 마케팅 수신 동의가 ${this.marketing_app? "동의":"거부"}처리되었습니다`)
                    this.password_prev = ""
                    this.password_next = ""
                    this.password_check = ""
                    this.load()
                }
            })
        },

        // 수정하기
        submit: _.debounce(async function() {
            // 입력값 기본 검증
            this.$v.$touch()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                this.$http.post("/api/user/select/login", {
                    params: {
                        user_id: this.$store.state.client_user.user_id,
                        password:this.password_prev
                    }
                }).then((res) => {
                    if(!res.data.length){
                        // 임시 비밀번호 확인
                        this.$http.post("/api/etc/temp_password/select/check2", {
                            params: {
                                user_id: this.$store.state.client_user.user_id,
                                password: this.password_prev
                            }
                        }).then((res) => {
                            if(!res.data.length){
                                this.$globalSnackbar.show(this.$store.state.language == 'English' ? "The current password does not match" : "현재 비밀번호가 일치하지 않습니다.")
                            }else{
                                this.$http.post("/api/user/update/password", {
                                    params: {
                                        user_id: this.$store.state.client_user.user_id,
                                        password_next: this.password_next
                                    }
                                }).then((res) => {
                                    if(res.data.affectedRows){
                                        this.$globalSnackbar.show(this.$store.state.language == 'English' ? "The user information has been updated" : "회원정보가 수정되었습니다.")
                                        this.password_prev = ""
                                        this.password_next = ""
                                        this.password_check = ""
                                        this.load()
                                    }
                                })
                            }
                        })
                    }else{
                        this.$http.post("/api/user/update/password", {
                            params: {
                                user_id: this.$store.state.client_user.user_id,
                                password_next: this.password_next
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                this.$globalSnackbar.show(this.$store.state.language == 'English' ? "The user information has been updated" : "회원정보가 수정되었습니다.")
                                this.password_prev = ""
                                this.password_next = ""
                                this.password_check = ""
                                this.load()
                            }
                        })
                    }

                    // if(!res.data[0]){
                    //     this.$globalSnackbar.show("현재 비밀번호가 맞지 않습니다.")
                    // }else{
                    //     // console.log("HI")
                    //     // this.$http.post("/api/user/update/password", {
                    //     //     params: {
                    //     //         user_id: this.$store.state.client_user.user_id,
                    //     //         password_next: this.password_next
                    //     //     }
                    //     // }).then((res) => {
                    //     //     if(res.data.affectedRows){
                    //     //         this.$globalSnackbar.show("회원정보가 수정되었습니다.")
                    //     //         this.password_prev = ""
                    //     //         this.password_next = ""
                    //     //         this.password_check = ""
                    //     //         this.load()
                    //     //     }
                    //     // })
                    // }
                })
            }
        }, 500),

        // 회원탈퇴하기
        leaveSubmit(){
            this.$http.post("/api/user/delete", {
                params: {
                    user_id: this.$store.state.client_user.user_id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    this.$globalSnackbar.show(this.$store.state.language == 'English' ? "Delete Account has been completed" : "회원탈퇴가 완료되었습니다.")
                    this.$router.push('/auth/logout')
                }
            })
        }
    }
}
</script>
<style scoped>
/* 리스트 기본 이펙트 제거 */
::v-deep .v-list-item:before,
::v-deep .v-list-item:hover:before,
::v-deep .v-list-item--active:before,
::v-deep .v-list-item--active:hover:before,
::v-deep .v-list-item:focus:before {
    opacity: 0;
}
</style>
<style scoped>
/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* 입력란 공통 (fieldset) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.custom_table tr td{
    padding:0px;
}

.custom_table tr td:first-child{
    width:120px;
    font-weight:bold;
}

</style>