const methods = {
    // # 환전통화별 표시탭 토글
    toggle_show_tab(index, exchange_list) {
        this.$set(
            exchange_list[index], 
            'show_tab', 
            exchange_list[index].show_tab === '만억조' ? 'KMB' : '만억조'
        )

        // show_tab_touch 속성을 true로 변경
        this.$set(exchange_list[index], 'show_tab_touch', true)
    },

    // # 기본통화랑 환전통화 사이 환전 배율 계산
    calculate_multiplier(value, referenceValue) {

        const ratio = this.$math.divide(value, referenceValue);
        let multiplier = 1;
        let adjustedValue = ratio;

        if(this.$store.state.language == 'English') {
            return {
                multiplier: this.$toComma(multiplier), // 표시할 배율
                formattedValue: this.$toComma(this.$math.divide(1, adjustedValue).toFixed(2)) // 소수점 2자리 표시
            }
        }

        if(this.$store.state.language == '한국어') {
            // 처음 0이 아닌 숫자가 2개 나올 때까지 곱하기
            while (adjustedValue < 1) {
                adjustedValue *= 10;
                multiplier *= 10;
            }

            return {
                multiplier: this.$toComma(multiplier), // 표시할 배율
                formattedValue: this.$toComma(adjustedValue.toFixed(2)) // 소수점 2자리 표시
            }
        }
    },

     // # 기본통화랑 환전통화 사이 환전 배율 계산
     calculate_multiplier2(value, referenceValue) {

        const ratio = this.$math.divide(value, referenceValue);
        let multiplier = 1;
        let adjustedValue = ratio


        // 처음 0이 아닌 숫자가 2개 나올 때까지 곱하기
        while (adjustedValue < 1) {
            adjustedValue *= 10;
            multiplier *= 10;
        }

        return {
            multiplier: this.$toComma(multiplier), // 표시할 배율
            formattedValue: this.$toComma(adjustedValue.toFixed(2)) // 소수점 2자리 표시
        }
    },

    // # 선택한 환전통화 확장
    selected_expand(item, exchange_list) {
        // 각 요소를 직접 수정
        exchange_list.forEach(e => {
            this.$set(e, 'is_expanded', e === item)
        })
    },

    // # 환전통화 순서 반전
    swap_currency(exchange_list) {
        // 2개 환전통화 순서 반전
        const temp = exchange_list[0]
        this.$set(exchange_list, 0, exchange_list[1])
        this.$set(exchange_list, 1, temp)

        // 2개 환전통화 확장 닫기
        exchange_list.forEach(item => item.is_expanded = false)
    },
}

export default methods