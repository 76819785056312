const methods = {
    // # 기본모드
    defaultMode_KeyHandler(list, exchange_list, scales, key){
        let last = list.at(-1)

        // # keyList 저장
        this.$keyList_Handler(key, list)

        // # 처음 시작 (이면서 기능키는 아닌 경우)
        if(list.length == 0 && this.$keyType(key) != '기능키'){
            // # 숫자키
            if(this.$keyType(key) == '숫자키'){
                list.push({
                    operator: null,
                    number: key == "."? '0.' : key,
                    scale: null,
                    edit: false,
                    edit_number: "",
                    edit2: false,
                    edit_operator: "",
                })
            }

            // # 단위키
            else if(this.$keyType(key) == '단위키'){
                list.push({
                    operator: null,
                    number: 1,
                    scale: key,
                    edit: false,
                    edit_number: "",
                    edit2: false,
                    edit_operator: "",
                })
            }

            // # 연산자키
            else if(this.$keyType(key) == '연산자키'){
                list.push({
                    operator: key,
                    number: null,
                    scale: null,
                    edit: false,
                    edit_number: "",
                    edit2: false,
                    edit_operator: "",
                })
            }
        }

        // # 기능키
        else if(this.$keyType(key) == '기능키'){
            // # 초기화
            if (key == "AC") {
                console.log("초기화")
                list.splice(0)
                this.$store.commit('reset_result')
            }

            // # 마지막 지우기
            else if (key == "←") {
                // 숫자 O & 단위 X : 숫자 맨 끝 제거
                if(list.length && last.number && last.scale == null){

                    if(list.length > 1){
                        // 연산자가 있으면서 2개 이상인 경우
                        if(last.number.toString().length >= 2){
                            last.number = last.number.toString().slice(0, -1)
                        }else{
                            last.number = null
                        }
                    }
                    // 첫 요소일 경우
                    else{
                        // 연산자가 있으면서 2개 이상인 경우
                        if(last.number.toString().length >= 2){
                            last.number = last.number.toString().slice(0, -1)
                        }else{
                            list.splice(0)
                        }
                    }
                }

                // 숫자 O & 단위 O : 단위 곱한 뒤 숫자 맨 끝 제거
                else if(list.length && last.number && last.scale != null){
                    let multipliedNumber = last.number * scales[last.scale]
                    last.number = multipliedNumber.toString().slice(0,-1)
                    last.scale = null
                }

                // 숫자 X & 단위 X & 연산자 ? : 연산자 맨 끝 제거
                else if(list.length && last.operator && last.operator != null){
                    // 연산자가 있으면서 2개 이상인 경우
                    if(last.operator.length >= 2){
                        last.operator = last.operator.slice(0, -1)
                    }

                    // 연산자 & 숫자 둘다 없어지면 요소 통쨰로 삭제
                    else{
                        list.pop()
                    }
                }
            }

            // # 저장하기
            else if (key == "=") {
                if(list.length){
                    // # 객체계산식을 문자열계산식으로 변환
                    let value = this.$list_to_evaluated_number(list)

                    // 음수
                    if(value < 0){
                        list.splice(0)  // 배열 비우기
                        list.push({      // 새로운 요소 추가
                            operator: "-",
                            number: this.$math.abs(value),
                            scale: null,
                            edit: false,
                            edit_number: "",
                            edit2: false,
                            edit_operator: ""
                        })
                    }

                    // 양수
                    else{
                        list.splice(0)  // 배열 비우기
                        list.push({      // 새로운 요소 추가
                            operator: null,
                            number: value,
                            scale: null,
                            edit: false,
                            edit_number: "",
                            edit2: false,
                            edit_operator: ""
                        })
                    }
                }

                if(this.$store.state.main_tab == '환전' && exchange_list.some(item => item.is_expanded)){
                    exchange_list[0].is_expanded = false
                }
            }

            // # Tab
            else if(key == "Tab"){
                let event = {
                    key: "Tab"
                }
                
                this.$tab_key_to_update_tabs(event, list)
                return
            }
        }

        // # 숫자키
        else if(this.$keyType(key) == '숫자키'){
            // # 숫자 없음
            if(last.number == null){
                // ) → )X
                if(last.operator && last.operator.slice(-1) == ')') last.operator += '*'

                if(list.length > 1 && last && last.operator.charAt(0) == "("){
                    last.operator = "*" + last.operator
                }

                last.number = key == "."? "0." : key
            }

            // # 숫자 있음
            else{
                // // 15자 (1000조, 1000T) 초과 입력 불가
                if(last.number.length > 15){
                    return
                }

                // # 단위 없음
                if(last.scale == null){
                    // . 일 경우
                    if(key == '.'){
                        last.number = last.number + '.'
                    }

                    // # 만억조
                    else if(this.$store.state.input_tab == "만억조"){
                        // 소수점 이후 숫자 입력
                        if(this.$lastNumberKeySet().includes('.')){
                            last.number = last.number + key
                        }

                        // 0만으로 이루어진 경우
                        else if(this.$lastNumberKeySet().split('').every(char => char === '0')){
                            last.number = this.$math.multiply(last.number, 10)
                        }

                        // # 1만 이상
                        else if(last.number > 10000 && this.$lastNumberKeySet().length < 5){
                            let test = this.$math.subtract(last.number, this.$lastNumberKeySet2())
                            // last.number = this.$math.add(test, this.$lastNumberKeySet())
                            if(last.number.length > 15){
                                return
                            }else{
                                last.number = this.$math.add(test, this.$lastNumberKeySet())
                            }
                        // # 1만 이하
                        }else{
                            // last.number = last.number + key
                            if(last.number.length > 15){
                                return
                            }else{
                                last.number = last.number + key
                            }
                        }
                    }

                    // # KMB
                    else if(this.$store.state.input_tab == "KMB"){
                        // 소수점 이후 숫자 입력
                        if(this.$lastNumberKeySet().includes('.')){
                            last.number = last.number + key
                        }

                        // 0만으로 이루어진 경우
                        else if(this.$lastNumberKeySet().split('').every(char => char === '0')){
                            last.number = this.$math.multiply(last.number, 10)
                        }

                        // # 1K 이상
                        else if(last.number > 1000 && this.$lastNumberKeySet().length < 4){
                            let test = this.$math.subtract(last.number, this.$lastNumberKeySet2())
                            // last.number = this.$math.add(test, this.$lastNumberKeySet())
                            if(last.number.length > 15){
                                return
                            }else{
                                last.number = this.$math.add(test, this.$lastNumberKeySet())
                            }
                        // # 1K 이하
                        }else{
                            // last.number = last.number + key
                            if(last.number.length > 15){
                                return
                            }else{
                                last.number = last.number + key
                            }
                        }
                    }
                }

                // # 단위 있음
                else{
                    // . 일 경우
                    if(key == '.'){
                        last.number = this.$math.multiply(last.number, scales[last.scale]) + '.'
                    }

                    // 0 일 경우
                    else if(this.$lastNumberKeySet() == "0"){
                        last.number = this.$math.multiply(last.number * 10, scales[last.scale])
                    }else{
                        // # (기존 숫자 * 단위) + 마지막 숫자 키 조합
                        last.number = this.$math.add(this.$math.multiply(last.number, scales[last.scale]), this.$lastNumberKeySet())
                    }

                    // # 단위 초기화
                    last.scale = null
                }
            }
        }

        // # 연산자키
        else if(this.$keyType(key) == '연산자키'){
            if(this.$store.state.main_tab == '환전' && exchange_list.every(item => !item.is_expanded)){
                exchange_list[0].is_expanded = true
            }

            // 숫자 없음
            if(last.number == null){
                if(last.operator.slice(-1) == "("){
                    last.operator += key
                    console.log("1")
                }

                // 기존 operator가 닫는 괄호인 경우 (근데 키가 곱셈인)
                else if(last.operator.slice(-1) == ")" && key == "*"){
                    last.operator += key
                    console.log("2")
                }

                // 기존 operator가 닫는 괄호인 경우 (근데 키가 )인)
                else if(last.operator.slice(-1) == ")" && key == ")"){
                    last.operator += key
                    console.log("3")
                }

                // 기존 operator가 닫는 괄호 나머지 경우
                else if(last.operator.slice(-1) == ")"){
                    last.operator += key
                    console.log("4")
                }

                // 기존 operator가 사칙연산인 경우
                else{
                    // X & - : X(- 
                    if(key == "-" && last.operator.slice(-1) == "*"){
                        last.operator += "("+key
                        console.log("5")
                    }

                    // / & - : /(- 
                    else if(key == "-" && last.operator.slice(-1) == "/"){
                        last.operator += "("+key
                        console.log("6")
                    }

                    else if(key == "("){
                        last.operator += key
                        console.log("9")
                    }

                    // 그 외에는 사칙연산 기호 교체
                    else{
                        if(last.operator.length > 1){
                            last.operator = last.operator.slice(0, -1);
                            last.operator += key
                            console.log("7")
                        }else{
                            last.operator = key 
                            console.log("8")
                        }
                    }
                }
            }

            // 숫자 있음
            else{
                list.push({
                    operator: key == "("?  "*"+ key : key,
                    number: null,
                    scale: null,
                    edit: false,
                    edit_number: "",
                    edit2: false,
                    edit_operator: "",
                })
            }
        }

        // # 단위키
        else if(this.$keyType(key) == '단위키'){
            // # 숫자 없음
            if(last.number == null){
                // ) → )X
                if(last.operator && last.operator.slice(-1) == ')') last.operator += '*'

                last.number = 1
                last.scale = key

                console.log(last)
            }

            // # 숫자 있음
            else{
                // # 단위 없음
                if(last.scale == null){
                    // # 숫자 입력 없이 바로 단위 입력 시
                    if(this.$lastNumberKeySet2().length == 0){
                        // 단위값 +1
                        last.number = this.$math.add(last.number, scales[key])
                    }

                    // # 숫자가 가장 낮은 단위보다는 작을 때
                    if(
                        ( this.$store.state.input_tab == "만억조" && this.$math.compare(last.number, 10000) < 0 )
                        ||
                        ( this.$store.state.input_tab == "KMB" && this.$math.compare(last.number, 1000) < 0 )
                    ){
                        // 단위만 추가
                        last.scale = key
                    }

                    // # 숫자가 가장 낮은 단위보다는 클 때
                    else{
                        // # 만억조
                        if(this.$store.state.input_tab == "만억조"){
                            // # 1만 이상
                            if(last.number > 10000 && this.$lastNumberKeySet2().length < 5){
                                let test = this.$math.subtract(last.number, this.$lastNumberKeySet2())
                                last.number = this.$math.add(test, this.$math.multiply(this.$lastNumberKeySet2(), scales[key]))
                            // # 1만 이하
                            }else{
                                // 단위값 +1
                                last.number = this.$math.add(last.number, scales[key])
                            }
                        }

                        // # KMB
                        if(this.$store.state.input_tab == "KMB"){
                            // # 1K 이상
                            if(last.number > 1000 && this.$lastNumberKeySet2().length < 4){
                                let test = this.$math.subtract(last.number, this.$lastNumberKeySet2())
                                last.number = this.$math.add(test, this.$math.multiply(this.$lastNumberKeySet2(), scales[key]))
                            // # 1K 이하
                            }else{
                                // 단위값 +1
                                last.number = this.$math.add(last.number, scales[key])
                            }
                        }
                    }
                }

                // # 단위 있음
                else{
                    // # 단위가 같을 시
                    if(last.scale == key){
                        last.number = this.$math.add(last.number, 1)
                    }

                    // # 단위가 다를 시
                    else{
                        // # 숫자 + 단위를 추가 입력 (X단위)
                        if(this.$isLastKeyNumberKey()){
                            // 단위값 X
                            last.number = this.$math.multiply(last.number, scales[key])

                            // 단위 초기화
                            last.scale = null
                        }

                        // # 숫자 + 단위를 추가 입력 (+단위)
                        else{
                            // 단위값 +1
                            last.number = this.$math.add(this.$math.multiply(last.number, scales[last.scale]), scales[key])

                            // 단위 초기화
                            last.scale = null
                        }
                    }
                }
            }
        }
    }
}

export default methods