const methods = {
    // # 모바일 브라우저 체크
    // check_mobile_browser() {
    //     const userAgent = navigator.userAgent || navigator.vendor || window.opera
        
    //     // 모바일 브라우저 체크를 위한 정규식
    //     const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i

    //     return mobileRegex.test(userAgent.toLowerCase())
    // }

    // # PC 크로미움 브라우저 체크
    check_pc_chromium_browser() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera
            // 크로미움 기반 브라우저 체크
        const isChromium = /chrome|chromium/i.test(userAgent) && !/mobile/i.test(userAgent) && !/macintosh/i.test(userAgent)
        return isChromium
    },
}

export default methods