const scales = {
	'만': 10000,
	'억': 100000000,
	'조': 1000000000000,
	'경': 10000000000000000,
	'K': 1000,
	'M': 1000000,
	'B': 1000000000,
	'T': 1000000000000
}

const scales_of_moj = {
    '경': 10000000000000000,
	'조': 1000000000000,
	'억': 100000000,
	'만': 10000
}

const scales_of_kmb = {
	'T': 1000000000000,
	'B': 1000000000,
	'M': 1000000,
	'K': 1000
}

const methods = {
    removeTrailingZeros(num) {
        const str = num.toString()
        const dotIndex = str.indexOf('.')
    
        if (dotIndex !== -1) {
            return str.replace(/\.?0+$/, '')
        }
        return str
    },

    removeInfiniteDecimal(num) {
        // 소수점 이하 자릿수 확인
        const decimalPlaces = num.toString().split('.')[1]?.length || 0
    
        // 소수점 이하 자릿수가 4보다 크면 무한 소수로 간주하고 소수점 4자리까지 반올림
        if (decimalPlaces > this.$store.state.sosujum) {
            return Number(this.$removeTrailingZeros(num)).toFixed(this.$store.state.sosujum)
        }

        // 그 외의 경우는 그대로 반환
        return num
    },

    // # 객체계산식을 문자열계산식으로 변환
    list_to_evaluated_number(list){
        let formula = list.map((item, index) => {
            if (index === list.length - 1 && item.number === null) {
                return ''
            }

            let scaleValue = scales[item.scale] || this.$math.bignumber(1)
            let number = item.number && item.number.toString().includes('%') 
                ? this.$math.divide(this.$math.bignumber(parseFloat(item.number)), this.$math.bignumber(100)) 
                : this.$math.bignumber(item.number)
            if (item.operator === null) {
                return `${this.$math.multiply(this.$math.bignumber(number), scaleValue)}`
            } else {
                return `${item.operator} ${this.$math.multiply(this.$math.bignumber(number), scaleValue)}`
            }
        }).filter(Boolean).join(' ')

        // 계산식에 빠진 뒷괄호 추가
        let openCount = (formula.match(/\(/g) || []).length
        let closeCount = (formula.match(/\)/g) || []).length
        
        while (openCount > closeCount) {
            formula += ')'
            closeCount++
        }

        // 계산 후 리턴
        return this.$math.evaluate(formula)
    },

    // # 키 목록에서 괄호 개수 확인
    isParenthesesOpen(list) {
        // 빈 괄호 체크
        if(
            list.at(-1).number == null
            && list.at(-1).operator
            && list.at(-1).operator.includes('(')
        ){
            return false
        }

        let OperatorList = list.map(e => e.operator)
        let openCount = 0
        let closeCount = 0

        // list 돌면서 괄호 수 체크
        OperatorList.forEach(operator => {
            if (operator) {
                openCount += (operator.match(/\(/g) || []).length
                closeCount += (operator.match(/\)/g) || []).length
            }
        })

        // 열린 괄호가 0개인 경우 false
        if (openCount == 0){
            return false
        }
        // 열림 괄호가 1개 이상일 경우
        else if(openCount > 0){
            // 열린 괄호가 더 많을 경우 true
            if(openCount > closeCount){
                return true
            }
            // 열린 괄호가 같은 경우 false
            else{
                return false
            }
        }
    },

    // # 사칙연산 기호 이쁘게 변경
    pretty_operator(operator) {
        // 없을시 null 리턴
        if(!operator) return null
        
        // 각 연산자 보기 이쁜 기호로 변경
        return operator.toString().replace("/", "÷").replace("*", "×").replace("-", "－").replace("+", "＋")
    },

    // # 사칙연산 기호 이쁘게 변경
    pretty_operator2(operator) {
        // 없을시 null 리턴
        if(!operator) return ""
        
        // 각 연산자 보기 이쁜 기호로 변경
        return operator.toString().replace("/", "÷").replace("*", "×").replace("-", "－").replace("+", "＋")
    },

    // # 원래 기호로 변경
    unpretty_operator(operator) {
        // 없을시 null 리턴
        if(!operator) return null
        
        // 각 이쁜 기호를 원래 기호로 변경
        return operator.toString().replace("÷", "/").replace("×", "*").replace("－", "-").replace("＋", "+")
    },

    // # 숫자 X 단위 => 계산된 숫자
    caculateTo000(number, scale) {
        // 단위 없을 시
		if (scale === null) {
            // BigNumber만 처리
			return this.$math.bignumber(number)
		}

        // 단위 있을 시
        else{
            // 단위랑 곱셈 처리
            return this.$math.multiply(this.$math.bignumber(number), scales[scale])
        }
	},

    // # 숫자 -> 계산된 숫자 + 만억조
    caculateNumberToMOJ(number) {
        // scales_of_moj 돌면서 몫 + 단위 => 나머지는 다시 반복
        let result = ''
        number = this.$math.bignumber(number) // 입력된 숫자를 BigNumber로 변환 (이게 없을시 소수점 문제 발생)
        for (let unit in scales_of_moj) {
            if (this.$math.largerEq(number, scales_of_moj[unit])) {
                const quotient = this.$math.floor(this.$math.divide(number, scales_of_moj[unit]))
                result += quotient + unit + ' '
                number = this.$math.mod(number, scales_of_moj[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(number, 0)) {
            result += number
        }

        return result
    },

    // # 숫자 -> 계산된 숫자 + KMB
    caculateNumberToKMB(number) {
        // scales_of_kmb 돌면서 몫 + 단위 => 나머지는 다시 반복
        let result = ''
        number = this.$math.bignumber(number) // 입력된 숫자를 BigNumber로 변환 (이게 없을시 소수점 문제 발생)
        for (let unit in scales_of_kmb) {
            if (this.$math.largerEq(number, scales_of_kmb[unit])) {
                const quotient = this.$math.floor(this.$math.divide(number, scales_of_kmb[unit]))
                result += quotient + unit + ' '
                number = this.$math.mod(number, scales_of_kmb[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(number, 0)) {
            result += number
        }

        return result.trim()
    },

    // # 숫자를 숫자 + 단위로 변환
    number_to_scaled_number(number, type) {
        // 변수 정의
        let isNegative = false
        let result = ''
        let selected_scales = type === '만억조' ? scales_of_moj : (type === 'KMB' ? scales_of_kmb : null)

        // 음수 여부 확인
        if (this.$math.smaller(number, 0)) {
            isNegative = true
            number = this.$math.abs(number)
        }
        
        // # 하나의 단위로 표시 가능한 경우
        for (let scale in selected_scales) {
            // 만약 현재 숫자가 단위보다 클 시
            if (this.$math.largerEq(number, selected_scales[scale])) {
                // 단위로 숫자를 나눈 값을 구함
                const divided_value = this.$math.divide(number, selected_scales[scale])

                // console.log(this.$math.number(divided_value))
                // console.log(divided_value.toFixed(2))
                // console.log(this.$math.equal(this.$math.number(divided_value), divided_value.toFixed(2)))
                // console.log(this.$math.number(divided_value) == divided_value.toFixed(2))

                // 나눈 값이 1 이상이고 소수점 2자리까지로 끝날 경우 바로 리턴
                if (
                    // 나눈 값이 1 이상
                    this.$math.largerEq(divided_value, 1)

                    // 나눈 값과 나눈 값을 소수점 2자리로 반올림 한 값이 같은지 확인해서 소수점 2자리로 끝나는지 확인
                    &&
                    this.$math.number(divided_value) == divided_value.toFixed(2)
                ) {
                    // bignumber를 콤마, 최대 소수점 2자리 설정값 반영, 문자열로 변환 + 단위
                    let result = this.$math.number(divided_value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum}) + scale
                    return isNegative ? '-' + result : result
                }
                break
            }
        }

        // # 하나의 단위로 표시할 수 없는 경우
        for (let scale in selected_scales) {
            // 만약 현재 숫자가 단위보다 클 시
            if (this.$math.largerEq(number, selected_scales[scale])) {
                // 단위로 나눈 몫을 구함
                const quotient = this.$math.floor(this.$math.divide(number, selected_scales[scale]))

                // 결과에 몫 + 단위 + 여백 추가
                result += this.$math.number(quotient).toLocaleString('en') + scale + ' '

                // 나머지 값을 number에 대입 (다음 단위 또는 마지막 나머지로 이동)
                number = this.$math.mod(number, selected_scales[scale])
            }
        }

        // # 나머지 값이 0보다 클 경우
        if (this.$math.larger(number, 0)) {
            // 결과에 나머지값을 콤마, 최대 소수점 2자리 설정값 반영하여 추가
            result += this.$math.number(number).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum}) 
        }

        // # 나머지 값이 없을 경우
        else{
            // 마지막 단위 뒤 여백 제거
            result = result.trim()
        }

        return isNegative ? '-' + result : result
    },

    // # 숫자 + 단위(KMB) -> 계산된 숫자 + 만억조
    caculateKMBtoMOJ(number, scale){
        // 값 세팅
        let value = this.$math.multiply(this.$math.bignumber(number), scales_of_kmb[scale])
        let result = ''

        /// scales_of_moj 돌면서 몫 + 단위 => 나머지는 다시 반복
        for (let unit in scales_of_moj) {
            if (this.$math.largerEq(value, scales_of_moj[unit])) {
                const quotient = this.$math.floor(this.$math.divide(value, scales_of_moj[unit]))
                result += quotient + unit + ' '
                value = this.$math.mod(value, scales_of_moj[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(value, 0)) {
            result += value
        }

        // 결과값 + trim() 없으면 곱셈값 리턴
        return result.trim() || value.toString()
    },

    // # 숫자 + 단위(만억조) -> 계산된 숫자 + KMB
    caculateMOJtoKMB(number, scale){
        // 값 세팅
        let value = this.$math.multiply(this.$math.bignumber(number), scales_of_moj[scale])
        let result = ''

        // scales_of_kmb 돌면서 몫 + 단위 => 나머지는 다시 반복
        for (let unit in scales_of_kmb) {
            if (this.$math.largerEq(value, scales_of_kmb[unit])) {
                const quotient = this.$math.floor(this.$math.divide(value, scales_of_kmb[unit]))
                result += quotient + unit + ' '
                value = this.$math.mod(value, scales_of_kmb[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(value, 0)) {
            result += value
        }

        // 결과값 + trim() 없으면 곱셈값 리턴
        return result.trim() || value.toString()
    }
}

export default methods