<template>
    <div
        ref="keypadContainer"
        class="fade-opacity pb-2 pt-6px px-1 rounded-t-xl"
        style="background:#F1F1F1; border:1px solid #cdd2da; border-bottom:none; z-index:50;"
    >
        <v-img
            v-if="$store.state.keypad_mode == 'expanded'"
            class="mx-auto mb-3"
            width="60"
            height="10"
            style="cursor:pointer;"
            src="@/assets/mode_down.svg"
            @click="$store.commit('update_keypad_mode', 'minimized')"
        ></v-img>

        <v-img
            v-if="$store.state.keypad_mode == 'minimized'"
            class="mx-auto mb-3"
            width="60"
            height="10"
            style="cursor:pointer;"
            src="@/assets/mode_up.svg"
            @click="$store.commit('update_keypad_mode', 'expanded')"
        ></v-img>

        <!-- # 버튼 (확대모드) -->
        <div
            v-if="$store.state.keypad_mode == 'expanded'"
            class="mx-2 mb-2"
        >
            <!-- ! 메모장 -->
            <!-- <v-btn
                width="46"
                height="46"
                fab
                depressed
                v-ripple="false"
                color="white"
                class="pa-3"
            >
                <v-img
                    src="@/assets/button/memo.svg"
                ></v-img>
            </v-btn> -->

            <!-- 180도 전환 -->
            <v-btn
                width="46"
                height="46"
                fab
                depressed
                v-ripple="false"
                :color="$store.state.rotate? '#F59E0B':'white'"
                class="pa-3"
                @click="$store.commit('update_rotate', !$store.state.rotate)"
            >
                <!-- 메모장 추가되면 ml-3 추가 -->
                <v-img
                    v-if="!$store.state.rotate"
                    src="@/assets/button/rotate.svg"
                ></v-img>
                <v-img
                    v-if="$store.state.rotate"
                    src="@/assets/button/rotate_white.svg"
                ></v-img>
            </v-btn>

            <!-- # 같이보기 -->
            <!-- <v-btn
                width="46"
                height="46"
                fab
                depressed
                v-ripple="false"
                :color="$store.state.twin? '#F59E0B':'white'"
                class="pa-3 ml-3"
                @click="$store.commit('update_twin', !$store.state.twin)"
            >
                <v-img
                    v-if="!$store.state.twin"
                    src="@/assets/button/twin.svg"
                ></v-img>
                <v-img
                    v-if="$store.state.twin"
                    src="@/assets/button/twin_white.svg"
                ></v-img>
            </v-btn> -->

            <!-- ! 숫자 읽어주기 (KR) -->
            <!-- <v-btn
                width="46"
                height="46"
                fab
                depressed
                v-ripple="false"
                color="white"
                class="pa-3 ml-3"
                style="position:relative;"
            >
                <p
                    style="position:absolute; top:-14px; left:50%; transform:translateX(-50%); color:#0369A1; text-shadow: 0px 0px 6px #ccc; font-size:11px;"
                >
                    KR <v-icon size="14" style="width:2px;">mdi-chevron-right</v-icon>
                </p>
                <v-img
                    src="@/assets/button/speaker.svg"
                ></v-img>
            </v-btn> -->

            <!-- ! 숫자 읽어주기 (EN) -->
            <!-- <v-btn
                width="46"
                height="46"
                fab
                depressed
                v-ripple="false"
                color="white"
                class="pa-3 ml-3"
                style="position:relative;"
            >
                <p
                    style="position:absolute; top:-14px; left:50%; transform:translateX(-50%); color:#0369A1; text-shadow: 0px 0px 6px #ccc; font-size:11px;"
                >
                    EN <v-icon size="14" style="width:2px;">mdi-chevron-right</v-icon>
                </p>
                <v-img
                    src="@/assets/button/speaker.svg"
                ></v-img>
            </v-btn> -->

            <!-- ! 숫자 읽어주기 (+) -->
            <!-- <v-btn
                width="46"
                height="46"
                fab
                depressed
                v-ripple="false"
                color="white"
                class="pa-3 ml-3"
                style="position:relative;"
            >
                <p
                    style="position:absolute; top:-12px; left:50%; transform:translateX(-50%); color:#0369A1; text-shadow: 0px 0px 6px #ccc; font-size:11px; font-weight:600;"
                >
                    ＋
                </p>
                <v-img
                    src="@/assets/button/speaker.svg"
                ></v-img>
            </v-btn> -->
        </div>

        <!-- # 버튼 (축소모드) & 입력탭 (input_tab) -->
        <div
            class="d-flex align-center"
        >
            <!-- # 버튼 (축소모드) -->
            <div
                v-if="$store.state.keypad_mode == 'minimized'"
                class="mb-5px"
            >
                <!-- ! 메모장 -->
                <!-- <v-btn
                    :width="$vuetify.breakpoint.xsOnly? 32:36"
                    :height="$vuetify.breakpoint.xsOnly? 32:36"
                    fab
                    depressed
                    v-ripple="false"
                    color="transparent"
                    :class="$vuetify.breakpoint.xsOnly? 'pa-2 ml-2':'pa-3 ml-2'"
                >
                    <v-img
                        width="24"
                        height="24"
                        src="@/assets/button/memo.svg"
                    ></v-img>
                </v-btn> -->

                <!-- 180도 전환 -->
                <v-btn
                    :width="$vuetify.breakpoint.xsOnly? 32:36"
                    :height="$vuetify.breakpoint.xsOnly? 32:36"
                    fab
                    depressed
                    v-ripple="false"
                    :color="$store.state.rotate? '#F59E0B':'transparent'"
                    :class="$vuetify.breakpoint.xsOnly? 'pa-2 ml-6px':'pa-3 ml-2'"
                    @click="$store.commit('update_rotate', !$store.state.rotate)"
                >
                    <v-img
                        v-if="!$store.state.rotate"
                        width="24"
                        height="24"
                        src="@/assets/button/rotate.svg"
                    ></v-img>
                    <v-img
                        v-if="$store.state.rotate"
                        width="24"
                        height="24"
                        src="@/assets/button/rotate_white.svg"
                    ></v-img>
                </v-btn>

                <!-- # 같이보기 -->
                <!-- <v-btn
                    :width="$vuetify.breakpoint.xsOnly? 32:36"
                    :height="$vuetify.breakpoint.xsOnly? 32:36"
                    fab
                    depressed
                    v-ripple="false"
                    :color="$store.state.twin? '#F59E0B':'transparent'"
                    :class="$vuetify.breakpoint.xsOnly? 'pa-2 ml-6px':'pa-3 ml-2'"
                    @click="$store.commit('update_twin', !$store.state.twin)"
                >
                    <v-img
                        v-if="!$store.state.twin"
                        width="24"
                        height="24"
                        src="@/assets/button/twin.svg"
                    ></v-img>
                    <v-img
                        v-if="$store.state.twin"
                        width="24"
                        height="24"
                        src="@/assets/button/twin_white.svg"
                    ></v-img>
                </v-btn> -->

                <!-- ! 숫자 읽어주기 (KR) -->
                <!-- <v-btn
                    :width="$vuetify.breakpoint.xsOnly? 32:36"
                    :height="$vuetify.breakpoint.xsOnly? 32:36"
                    fab
                    depressed
                    v-ripple="false"
                    color="transparent"
                    :class="$vuetify.breakpoint.xsOnly? 'pa-2 ml-6px':'pa-3 ml-2'"
                    style="position:relative;"
                >
                    <p
                        style="position:absolute; top:-13px; left:50%; transform:translateX(-50%); text-shadow: 0px 0px 6px #ccc; color:#0369A1; font-size:10px;"
                    >
                        KR
                        <v-icon size="14" style="width:2px;">mdi-chevron-right</v-icon>
                    </p>
                    <v-img
                        width="24"
                        height="24"
                        src="@/assets/button/speaker.svg"
                    ></v-img>
                </v-btn> -->

                <!-- ! 숫자 읽어주기 (EN) -->
                <!-- <v-btn
                    :width="$vuetify.breakpoint.xsOnly? 32:36"
                    :height="$vuetify.breakpoint.xsOnly? 32:36"
                    fab
                    depressed
                    v-ripple="false"
                    color="transparent"
                    :class="$vuetify.breakpoint.xsOnly? 'pa-2 ml-6px':'pa-3 ml-2'"
                    style="position:relative;"
                >
                    <p
                        style="position:absolute; top:-13px; left:50%; transform:translateX(-50%); text-shadow: 0px 0px 6px #ccc; color:#0369A1; font-size:10px;"
                    >
                        EN
                        <v-icon size="14" style="width:2px;">mdi-chevron-right</v-icon>
                    </p>
                    <v-img
                        width="24"
                        height="24"
                        src="@/assets/button/speaker.svg"
                    ></v-img>
                </v-btn> -->

                <!-- ! 숫자 읽어주기 (+) -->
                <!-- <v-btn
                    :width="$vuetify.breakpoint.xsOnly? 32:36"
                    :height="$vuetify.breakpoint.xsOnly? 32:36"
                    fab
                    depressed
                    v-ripple="false"
                    color="transparent"
                    :class="$vuetify.breakpoint.xsOnly? 'pa-2 ml-6px':'pa-3 ml-2'"
                    style="position:relative;"
                >
                    <p
                        style="position:absolute; top:-10px; left:50%; transform:translateX(-50%); text-shadow: 0px 0px 6px #ccc; color:#0369A1; font-size:10px; font-weight:600;"
                    >
                        ＋
                    </p>
                    <v-img
                        width="24"
                        height="24"
                        src="@/assets/button/speaker.svg"
                    ></v-img>
                </v-btn> -->
            </div>
        </div>

        <!-- # 기본 키패드 -->
        <div
            v-if="$store.state.keypad_mode == 'expanded'"
            class="key_pad_style"
        >
            <!-- # 0 줄 -->
            <v-row dense no-gutters class="px-1">
                <!--  -->
                <v-col class="divide_five">

                </v-col>

                <!--  -->
                <v-col class="divide_five d-flex gap-2">

                </v-col>

                <!--  -->
                <v-col class="divide_five">

                </v-col>

                <!-- # ← -->
                <v-col
                    v-if="$store.state.language == '한국어'"
                    class="divide_five d-flex justify-space-between"
                >
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != '만억조' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == 'KMB'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', '만억조');"
                    >   
                        만
                    </v-btn>
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != '만억조' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == 'KMB'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', '만억조');"
                    >   
                        억
                    </v-btn>
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != '만억조' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == 'KMB'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', '만억조');"
                    >   
                        조
                    </v-btn>
                </v-col>

                <!-- # ÷ -->
                <v-col class="divide_five d-flex justify-space-between">
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != 'KMB' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == '만억조'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', 'KMB')"
                    >   
                        K
                    </v-btn>
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != 'KMB' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == '만억조'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', 'KMB')"
                    >   
                        M
                    </v-btn>
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != 'KMB' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == '만억조'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', 'KMB')"
                    >   
                        B
                    </v-btn>
                </v-col>

                <!-- # ← -->
                <v-col
                    v-if="$store.state.language != '한국어'"
                    class="divide_five d-flex justify-space-between"
                >
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != '만억조' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == 'KMB'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', '만억조');"
                    >   
                        만
                    </v-btn>
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != '만억조' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == 'KMB'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', '만억조');"
                    >   
                        억
                    </v-btn>
                    <v-btn
                        width="29%"
                        x-small
                        depressed
                        v-ripple="false"
                        :disabled="$store.state.input_tab != '만억조' && ($store.state.edit_mode || $store.state.edit_mode2)"
                        style="padding-bottom:2px; margin:1px; letter-spacing:1px !important; min-width:0px;"
                        class="rounded-5 text-caption font-weight-bold"
                        :style="$store.state.input_tab == 'KMB'? 'color:#b3b3b3; background:#fbfbfb; box-shadow:none !important; border: 1px solid #e2e2e8 !important;':'background:white; color:#324D78;'"
                        @click="$store.commit('update_input_tab', '만억조');"
                    >   
                        조
                    </v-btn>
                </v-col>
            </v-row>

            <!-- # 첫 줄 -->
            <v-row dense no-gutters class="px-1">
                <!-- # AC -->
                <v-col class="divide_five" style="position:relative;">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('AC')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('AC')"
                    >
                        {{$store.state.edit_mode || $store.state.edit_mode2 ? "C" : "AC"}}
                        <p
                            style="position:absolute; top:-8px; left:-6px; color:#858D99; font-size:14px; font-weight:500; font-family: Consolas, monospace;"
                            class=""
                        >
                            ESC
                        </p>
                    </v-btn>
                </v-col>

                <!-- # 괄호 -->
                <v-col class="divide_five d-flex gap-2">
                    <!-- # ( -->
                    <v-btn
                        :disabled="$store.state.disable_key.includes('(')"
                        :height="computed_height"
                        x-small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-12 text-subtitle-1 font-weight-bold flex-grow-1 yellow_key"
                        style="min-width:0px;"
                        @click="keyPress('(')"
                    >
                        (
                    </v-btn>

                    <!-- # ) -->
                    <div
                        class="flex-grow-1 ml-6px"
                    >
                        <v-btn
                            :disabled="$store.state.disable_key.includes(')')"
                            :height="computed_height"
                            x-small
                            color="white"
                            depressed
                            block
                            v-ripple="false"
                            class="rounded-12 text-subtitle-1 font-weight-bold yellow_key"
                            style="min-width:0px;"
                            @click="keyPress(')')"
                        >
                            )
                        </v-btn>
                    </div>
                </v-col>

                <!-- # % -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('%')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('%')"
                    >
                        %
                    </v-btn>
                </v-col>

                <!-- # ← -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('←')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold indigo_key"
                        @click="keyPress('←')"
                    >
                        ←
                    </v-btn>
                </v-col>

                <!-- # ÷ -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('/')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold yellow_key"
                        @click="keyPress('/')"
                    >
                        ÷
                    </v-btn>
                </v-col>
            </v-row>

            <!-- # 2번째 줄 -->
            <v-row dense no-gutters class="px-1">
                <!-- # 7 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('7')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('7')"
                    >
                        7
                    </v-btn>
                </v-col>

                <!-- # 8 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('8')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('8')"
                    >
                        8
                    </v-btn>
                </v-col>

                <!-- # 9 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('9')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('9')"
                    >
                        9
                    </v-btn>
                </v-col>

                <!-- # 조 : B -->
                <v-col class="divide_five" style="position:relative;">
                    <v-btn
                        :disabled="$store.state.input_tab == '만억조'? $store.state.disable_key.includes('조'):$store.state.disable_key.includes('B')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold px-0 indigo_key"
                        @click="$store.state.input_tab == '만억조'? keyPress('조'): keyPress('B')"
                    >
                        <span style="font-size:10px;" class="indigo_key font-weight-bold mb-3">{{$isLastKeyNumberKey() && $lastNumberKeySet().length < 5? '':'＋'}}</span>
                        {{$store.state.input_tab == '만억조'? '조':'B'}}
                        <span style="font-size:10px;" class=" white--text font-weight-bold mt-2px">{{$isLastKeyNumberKey() && $lastNumberKeySet().length < 5? '':'＋'}}</span>
                    </v-btn>

                    <p
                        v-if="$store.state.input_tab == '만억조'"
                        style="position:absolute; top:8px; right:16px; color:#858D99; font-size:15px; font-weight:500; font-family: Consolas, monospace;"
                        class=""
                    >
                        E
                    </p>
                </v-col>

                <!-- # × -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('*')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold yellow_key"
                        @click="keyPress('*')"
                    >
                        ×
                    </v-btn>
                </v-col>
            </v-row>

            <!-- # 3번째 줄 -->
            <v-row dense no-gutters class="px-1">
                <!-- # 4 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('4')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('4')"
                    >
                        4
                    </v-btn>
                </v-col>

                <!-- # 5 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('5')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('5')"
                    >
                        5
                    </v-btn>
                </v-col>

                <!-- # 6 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('6')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('6')"
                    >
                        6
                    </v-btn>
                </v-col>

                <!-- # 억 : M -->
                <v-col class="divide_five" style="position:relative;">
                    <v-btn
                        :disabled="$store.state.input_tab == '만억조'? $store.state.disable_key.includes('억'):$store.state.disable_key.includes('M')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold px-0 indigo_key"
                        @click="$store.state.input_tab == '만억조'? keyPress('억'): keyPress('M')"
                    >
                        <span style="font-size:10px;" class="indigo_key font-weight-bold mb-3">{{$isLastKeyNumberKey() && $lastNumberKeySet().length < 5? '':'＋'}}</span>
                        {{$store.state.input_tab == '만억조'? '억':'M'}}
                        <span style="font-size:10px;" class="white--text font-weight-bold mt-2px">{{$isLastKeyNumberKey() && $lastNumberKeySet().length < 5? '':'＋'}}</span>
                    </v-btn>

                    <p
                        v-if="$store.state.input_tab == '만억조'"
                        style="position:absolute; top:8px; right:16px; color:#858D99; font-size:15px; font-weight:500; font-family: Consolas, monospace;"
                        class=""
                    >
                        W
                    </p>
                </v-col>

                <!-- # － -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('-')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold yellow_key"
                        @click="keyPress('-')"
                    >
                        －
                    </v-btn>
                </v-col>
            </v-row>

            <!-- # 4번째 줄 -->
            <v-row dense no-gutters class="px-1">
                <!-- # 1 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('1')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('1')"
                    >
                        1
                    </v-btn>
                </v-col>

                <!-- # 2 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('2')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('2')"
                    >
                        2
                    </v-btn>
                </v-col>

                <!-- # 3 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('3')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('3')"
                    >
                        3
                    </v-btn>
                </v-col>

                <!-- # 만 : K -->
                <v-col class="divide_five" style="position:relative;">
                    <v-btn
                        :disabled="$store.state.input_tab == '만억조'? $store.state.disable_key.includes('만'):$store.state.disable_key.includes('K')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold px-0 indigo_key"
                        @click="$store.state.input_tab == '만억조'? keyPress('만'): keyPress('K')"
                    >
                        <span style="font-size:10px;" class="indigo_key font-weight-bold mb-3">{{$isLastKeyNumberKey() && $lastNumberKeySet().length < 5? '':'＋'}}</span>
                        {{$store.state.input_tab == '만억조'? '만':'K'}}
                        <span style="font-size:10px;" class="white--text font-weight-bold mt-2px">{{$isLastKeyNumberKey() && $lastNumberKeySet().length < 5? '':'＋'}}</span>
                    </v-btn>

                    <p
                        v-if="$store.state.input_tab == '만억조'"
                        style="position:absolute; top:8px; right:16px; color:#858D99; font-size:15px; font-weight:500; font-family: Consolas, monospace;"
                        class=""
                    >
                        Q
                    </p>
                </v-col>

                <!-- # ＋ -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('+')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold yellow_key"
                        @click="keyPress('+')"
                    >
                        ＋
                    </v-btn>
                </v-col>
            </v-row>

            <!-- # 5번째 줄 -->
            <v-row dense no-gutters class="px-1">
                <!-- # . -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('.')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('.')"
                    >
                        .
                    </v-btn>
                </v-col>

                <!-- # 0 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('0')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('0')"
                    >
                        0
                    </v-btn>
                </v-col>

                <!-- # 00 -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('00')"
                        block
                        :height="computed_height"
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 flex-grow-1 font-weight-bold indigo_key"
                        @click="keyPress('00')"
                    >
                        00
                    </v-btn>
                </v-col>

                <!-- # Tab -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('Tab')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold text-capitalize px-0 indigo_key"
                        style="letter-spacing:-0.4px !important;"
                        @click="keyPress('Tab')"
                    >
                        Mode
                    </v-btn>
                </v-col>

                <!-- # ＝ -->
                <v-col class="divide_five">
                    <v-btn
                        :disabled="$store.state.disable_key.includes('=')"
                        :height="computed_height"
                        block
                        small
                        color="white"
                        depressed
                        v-ripple="false"
                        class="rounded-15 text-subtitle-1 font-weight-bold indigo_key"
                        @click="keyPress('=')"
                    >
                        ＝
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        computed_height() {
            let height = 50

            if(this.$vuetify.breakpoint.height > 880) {
                return 50
            }
            else if(this.$vuetify.breakpoint.height <= 880) {
                return Math.floor(this.$vuetify.breakpoint.height / 17.6)
            }
        }
    },

    methods: {
		// 버튼 입력
		keyPress(key) {
			this.$emit("keyPress", key)
		}
	}
}
</script>
<style scoped>
/* @media (min-width: 1264px) and (max-width: 1903px) { */
@media (min-width: 200px) and (max-width: 4000px) {
    .divide_five {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
        padding:3px !important;
    }
}

.divide_five .v-btn{
    box-shadow:0px 0px 3px 1px #e2e2e8;
}

/* 비활성 배경 지정 */
::v-deep .divide_five .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background:#fbfbfb !important;
    /* box-shadow: none !important; */
}

.yellow_key{
    color: #F59E0BCC !important;
}

.indigo_key{
    color: #324D78 !important;
}

</style>
<style scoped>
.v-btn::before {
    background-color: transparent
}

::v-deep .key_pad_style .v-btn:not(.v-btn--disabled) {
    box-shadow: 1px 1px 5px 0px rgba(170, 170, 204, 0.5) !important;
}

::v-deep .key_pad_style .v-btn.v-btn--disabled {
    border: 1px solid #e2e2e8 !important;
    box-shadow: none !important;
}
</style>