 <template>
    <v-app v-if="snackbar">
        <v-snackbar
            v-model="snackbar"
            centered
            rounded="10"
            :max-width="$vuetify.breakpoint.xsOnly ? '80vw' : '480px'"
            min-height="120"
            content-class="rounded-xl py-2 px-4"
            timeout="2400"
            light
        >
            <v-icon
                style="position:absolute; top:10px; right:10px;"
                size="24"
                color="#667080"
                @click="snackbar = false"
            >
                mdi-close
            </v-icon>
            <p
                class="my-0 primary--text text-center px-8 mt-1"
                style="font-size:18px; line-height:1.2; font-weight:500; word-break: keep-all; white-space: pre-line;"
            >
                {{message}}
            </p>
        </v-snackbar>
    </v-app>
</template>

<script>
export default {
    name: 'GlobalSnackbar',

    data: () => ({
        snackbar: false,
        message: "Membership registration was successful.\nAn authentication email was sent to the email you wrote.\nClick the `Mail Authentication` button in the email to complete the membership registration."
    }),

    methods: {
        show(message) {
            // this.message = message
            this.snackbar = true
        },

        close() {
            this.snackbar = false
        }
    }
}
</script>
