<template>
    <v-app :class="rotateClass">
        <router-view />
    </v-app>
</template>
<script>
export default {
    name: 'App',

    watch: {
        $route(to, from) {
            if(to.path != "/" && this.$store.state.rotate){
                this.$store.commit("update_rotate", false)
            }
        }
    },

    computed: {
        rotateClass() {
            return this.$store.state.rotate ? 'rotate' : ''
        }
    }
}
</script>
<style>
.rotate {
    transform: rotate(180deg);
}
</style>
<style>
/* Chrome, Safari, Edge 전용 */
::-webkit-scrollbar {
    width: 16px; /* 스크롤바 너비 */
    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
}

::-webkit-scrollbar-track {
    background: #f5f5f5; /* 스크롤바 배경 */
    width: 16px;
    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
}

::-webkit-scrollbar-thumb {
    background-color: #ACACAC; /* 스크롤바 색상 */
    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
    width:8px !important;
    min-height:40px !important;
    border: 4px solid #f5f5f5; /* 스크롤바 막대 테두리 설정  */
}

/* ::-webkit-scrollbar-thumb:hover, */
::-webkit-scrollbar-thumb:active {
    background-color: #0369a1 !important; /* 스크롤바 호버 색상 */
}
</style>