import { render, staticRenderFns } from "./ExchangeListAdd.vue?vue&type=template&id=a89ba94c&scoped=true"
import script from "./ExchangeListAdd.vue?vue&type=script&lang=js"
export * from "./ExchangeListAdd.vue?vue&type=script&lang=js"
import style0 from "./ExchangeListAdd.vue?vue&type=style&index=0&id=a89ba94c&prod&scoped=true&lang=css"
import style1 from "./ExchangeListAdd.vue?vue&type=style&index=1&id=a89ba94c&prod&scoped=true&lang=css"
import style2 from "./ExchangeListAdd.vue?vue&type=style&index=2&id=a89ba94c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a89ba94c",
  null
  
)

export default component.exports