import caculator from '@/methods/caculator.js'
import commas from '@/methods/commas.js'
import default_mode from '@/methods/default_mode.js'
import edit_mode from '@/methods/edit_mode.js'
import edit_mode2 from '@/methods/edit_mode2.js'
import exchange from '@/methods/exchange.js'
import formula from '@/methods/formula.js'
import keys from '@/methods/keys.js'
import results from '@/methods/results.js'
import user_device from '@/methods/user_device.js'

export default {
    install (Vue) {
        // # 계산 로직
        Vue.prototype.$summary_number_to_2 = caculator.summary_number_to_2
        Vue.prototype.$removeTrailingZeros = caculator.removeTrailingZeros
        Vue.prototype.$removeInfiniteDecimal = caculator.removeInfiniteDecimal
        Vue.prototype.$list_to_evaluated_number = caculator.list_to_evaluated_number
        Vue.prototype.$isParenthesesOpen = caculator.isParenthesesOpen
        Vue.prototype.$pretty_operator = caculator.pretty_operator
        Vue.prototype.$pretty_operator2 = caculator.pretty_operator2
        Vue.prototype.$unpretty_operator = caculator.unpretty_operator
        Vue.prototype.$caculateNumberToMOJ = caculator.caculateNumberToMOJ
        Vue.prototype.$number_to_scaled_number = caculator.number_to_scaled_number
        Vue.prototype.$caculateNumberToKMB = caculator.caculateNumberToKMB
        Vue.prototype.$caculateMOJtoKMB = caculator.caculateMOJtoKMB
        Vue.prototype.$caculateKMBtoMOJ = caculator.caculateKMBtoMOJ
        Vue.prototype.$caculateTo000 = caculator.caculateTo000

        // # 콤마
        Vue.prototype.$inputToComma = commas.inputToComma
        Vue.prototype.$toComma = commas.toComma
        Vue.prototype.$toUnComma = commas.toUnComma

        // # 기본모드
        Vue.prototype.$defaultMode_KeyHandler = default_mode.defaultMode_KeyHandler

        // # 수정모드
        Vue.prototype.$getTextWidth = edit_mode.getTextWidth
        Vue.prototype.$dynamicWidth = edit_mode.dynamicWidth
        Vue.prototype.$edit_start = edit_mode.edit_start
        Vue.prototype.$edit_end = edit_mode.edit_end

        // # 수정모드2
        Vue.prototype.$getTextWidth2 = edit_mode2.getTextWidth2
        Vue.prototype.$dynamicWidth2 = edit_mode2.dynamicWidth2
        Vue.prototype.$edit_start2 = edit_mode2.edit_start2
        Vue.prototype.$edit_end2 = edit_mode2.edit_end2

        // # 환전
        Vue.prototype.$toggle_show_tab = exchange.toggle_show_tab
        Vue.prototype.$calculate_multiplier = exchange.calculate_multiplier
        Vue.prototype.$calculate_multiplier2 = exchange.calculate_multiplier2
        Vue.prototype.$selected_expand = exchange.selected_expand
        Vue.prototype.$swap_currency = exchange.swap_currency

        // # 계산식
        Vue.prototype.$formula_main = formula.formula_main
        Vue.prototype.$formula_sub = formula.formula_sub

        // # 결과값
        Vue.prototype.$result_main = results.result_main
        Vue.prototype.$result_sub = results.result_sub
        Vue.prototype.$result_exchange_main = results.result_exchange_main
        Vue.prototype.$result_exchange_sub = results.result_exchange_sub

        // # 키
        Vue.prototype.$tab_key_to_update_tabs = keys.tab_key_to_update_tabs
        Vue.prototype.$edit_number_keyboard_allow_key = keys.edit_number_keyboard_allow_key
        Vue.prototype.$isLastKeyNumberKey = keys.isLastKeyNumberKey
        Vue.prototype.$lastNumberKeySet = keys.lastNumberKeySet
        Vue.prototype.$lastNumberKeySet2 = keys.lastNumberKeySet2
        Vue.prototype.$keyList_Handler = keys.keyList_Handler
        Vue.prototype.$checkDisableKey = keys.checkDisableKey
        Vue.prototype.$keyType = keys.keyType

        // # 사용자 기기환경 체크
        // Vue.prototype.$check_mobile_browser = user_device.check_mobile_browser
        Vue.prototype.$check_pc_chromium_browser = user_device.check_pc_chromium_browser

    }
}