<template>
    <div
        class="pa-2 rounded-10"
        style="width:340px;"
    >
        <div class="d-flex align-end pl-2 pr-1 pt-1">
            <!-- <v-icon
                size="20"
                style="padding-bottom:2px;"
                class="mr-6px"
                color="#f59e0b"
            >
                {{dialog_type == 'add' ? 'mdi-plus-circle-outline' : 'mdi-pencil-circle-outline'}}
            </v-icon> -->
            <p
                class="mb-0 text-truncate alter_black--text"
                style="font-size:16px; font-weight:500; padding-bottom:1px;"
            >
                {{ $store.state.language == 'English' ? 'Exchange' : '통화' }} 
                {{ $store.state.language == 'English' ? (dialog_type == 'add' ? 'Add' : 'Edit') : (dialog_type == 'add' ? 'Add' : 'Edit') }}
            </p>

            <v-spacer></v-spacer>

            <v-icon
                size="24"
                color="#667080"
                @click="$emit('close')"
            >
                mdi-close
            </v-icon>
        </div>

        <!-- # 검색 -->
        <div
            class="px-2 mobile mt-2"
        >
            <v-text-field
                hide-details="auto"
                dense
                outlined
                clearable
                class="rounded-15"
                color="primary"
                :placeholder="$store.state.language == 'English' ? 'Search' : '검색'"
                v-model="keyword"
                @input="debounceSearch()"
                @key.enter="debounceSearch()"
            >
                <template v-slot:prepend-inner>
                    <v-icon class="mr-1 mt-1px" size="18" color="#424361">mdi-magnify</v-icon>
                </template>
            </v-text-field>
        </div>

        <div class="d-flex justify-space-between px-2 my-1">
            <!-- # 통화 -->
            <v-btn
                width="32%"
                height="30"
                x-small
                depressed
                class="rounded-15 text-capitalize font-weight-medium"
                style="font-size:14px !important;"
                :style="list_type == '통화'? 'color:white; background:#95abb8;':'background:white;'"
                @click="change_list_type('통화')"
            >   
                {{ $store.state.language == 'English' ? 'Currency' : '통화' }}
            </v-btn>

            <!-- # 암호화폐 -->
            <v-btn
                width="32%"
                height="30"
                x-small
                depressed
                class="rounded-15 text-capitalize font-weight-medium ml-2"
                style="font-size:14px !important;"
                :style="list_type == '암호화폐'? 'color:white; background:#95abb8;':'background:white;'"
                @click="change_list_type('암호화폐')"
            >   
                {{ $store.state.language == 'English' ? 'Crypto' : '암호화폐' }}
            </v-btn>

            <v-spacer></v-spacer>

            <!-- # 즐겨찾기 -->
            <!-- <v-btn
                width="32%"
                height="30"
                x-small
                depressed
                class="rounded-15 text-capitalize font-weight-medium ml-2"
                style="font-size:14px !important;"
                :style="list_type == '즐겨찾기'? 'color:white; background:#95abb8;':'background:white;'"
                @click="change_list_type('즐겨찾기')"
            >   
                {{ $store.state.language == 'English' ? 'Favorites' : '즐겨찾기' }}
            </v-btn> -->
        </div>

        <!-- # 목록 (즐겨찾기) -->
        <div
            style="height:63vh; overflow-y:scroll;"
            ref="scrollableList"
        >
<!--            <div
                v-for="item in oneList" :key="item.name_en"
                class="list-item d-flex px-2 alter_black--text text-body-2 mt-"
                style="cursor:pointer;"
                @click="$emit('selected', item)"
            >
                <!~~ # 국가 ~~>
                <div
                    style="width:40%"
                    class="pl-1 d-flex align-center"
                >
                    <!~~ 이미지 ~~>
                    <v-sheet
                        width="24"
                        height="24"
                    >
                        <!~~ 통화 ~~>
                        <v-img
                            v-if="item.type == 'currency' && item.code"
                            class="rounded-circle"
                            width="24"
                            height="24"
                            style="box-shadow:0px 0px 4px #ccc;"
                            :src="require('@/assets/flags/'+item.code+'.svg')"
                        ></v-img>

                        <!~~ 암호화폐 ~~>
                        <v-img
                            v-if="item.type == 'coin'"
                            class="rounded-circle"
                            width="24"
                            height="24"
                            style="box-shadow:0px 0px 4px #ccc;"
                            :src="require('@/assets/coins/'+item.code+'.png')"
                        ></v-img>
                    </v-sheet>

                    <!~~ 글자 ~~>
                    <div
                        class="ml-2"
                    >
                        <!~~ 한글명 ~~>
                        <p
                            class="alter_black--text mb-0"
                        >
                            {{item.name_kr}}
                        </p>

                        <!~~ 코드명 ~~>
                        <p
                            class="alter_grey--text font-weight-bold mb-0"
                            style="margin-top:-2px;"
                        >
                            {{item.code}}
                        </p>
                    </div>
                </div>

                <!~~ # 숫자 & 즐겨찾기 ~~>
                <div
                    style="width:60%;"
                    class="d-flex pl-0 pr-2px font-weight-medium align-center"
                >
                    <!~~ 숫자 ~~>
                    <div
                        class="flex-grow-1 pr-2"
                    >
                        <p class="force-ltr text-right alter_black--text text-subtitle-1 font-weight-bold mb-0">
                            {{item.symbol}} {{$store.state.result?.toFixed(2)}}
                        </p>
                    </div>

                    <!~~ 즐겨찾기 ~~>
                    <v-icon size="20" class="ml-2" color="primary">mdi-star</v-icon>
                </div>
            </div>-->

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                size="60"
                class="d-flex mx-auto mt-16"
            ></v-progress-circular>
            
            <draggable
                v-if="!loading"
                v-model="list"
                ghost-class="ghost"
                @start="onStart"
                @end="onEnd"
                :disabled="true"
                :delay="150"
            >
                <div
                    v-for="(item, index) in filteredList" :key="index"
                    class="list-item d-flex pl-2 alter_black--text text-body-2"
                    style="cursor:pointer;"
                >
                    <!-- # 국가 -->
                    <div
                        @click="$emit('selected', item)"
                        style="width:36%; max-width:36%; overflow:hidden;"
                        class="pl-1 d-flex align-center"
                    >
                        <!-- 이미지 -->
                        <v-sheet
                            width="24"
                            height="24"
                        >
                            <!-- 통화 -->
                            <v-img
                                v-if="item.type == 'currency' && item.code"
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc; width:24px; height:24px;"
                                :src="require('@/assets/flags/'+item.code+'.svg')"
                            ></v-img>

                            <!-- 암호화폐 -->
                            <v-img
                                v-if="item.type == 'coin'"  
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc; width:24px; height:24px;"
                                :src="require('@/assets/coins/'+item.code+'.png')"
                            ></v-img>
                        </v-sheet>

                        <!-- 글자 -->
                        <div
                            class="ml-2"
                            style="min-width: 0; overflow: hidden; max-width: calc(100% - 30px);"
                        >
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-12">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        style="overflow: hidden;"
                                    >
                                        <p
                                            class="alter_black--text mb-0 text-truncate"
                                            style="letter-spacing:-0.4px; font-size:12px; line-height:16px;"
                                            @click.stop
                                        >
                                            {{$store.state.language == 'English' ? item.name_en : item.name_kr}}
                                        </p>
                                    </div>
                                </template>
                                <p
                                    class="white--text mb-0"
                                    style="letter-spacing:-0.4px;"
                                >
                                    {{$store.state.language == 'English' ? item.name_en : item.name_kr}}
                                </p>
                            </v-tooltip>

                            <!-- 코드명 -->
                            <p
                                class="alter_grey--text font-weight-bold mb-0"
                                style="margin-top:-2px;  font-size:12px;"
                            >
                                {{item.code}}
                            </p>
                        </div>
                    </div>

                    <!-- # 숫자 & 즐겨찾기 -->
                    <div
                        style="width:60%;"
                        class="d-flex pl-0 pr-2px font-weight-medium align-center"
                    >
                        <!-- # 숫자 표시 (결과값이 있음)  -->
                        <div
                            @click="$emit('selected', item)"
                            v-if="$store.state.result"
                            class="flex-grow-1 pl-1 pr-2 text-truncate force-ltr text-right alter_black--text text-subtitle-1 mb-0"
                        >
                            <span class="mr-2px primary--text text--darken-1">{{item.symbol}}</span>
                            {{item.calculatedValue.integer}}{{item.calculatedValue.decimal}}
                        </div>

                        <!-- # 환율 표시 (결과값이 없음) -->
                        <div
                            @click="$emit('selected', item)"
                            v-else
                            class="flex-grow-1 pr-2"
                        >
                            <!-- <p v-if="item.code != 'KRW'" class="force-ltr text-right text-body-2 mb-0" style="color:#888;">
                                <span v-if="item.symbol">{{item.symbol}}{{item.value < 10 ? '100' : '1'}} =</span>
                                {{exchange_list[0].symbol}}{{$toComma(item.value < 10 ? (item.value * 100).toFixed(2) : item.value.toFixed(2))}}
                            </p> -->


                            <!-- <div
                                v-if="item.type == 'currency' && (item.code != 'KRW' && $store.state.language == '한국어') || (item.code != 'USD' && $store.state.language == 'English')"
                                class="mb-0 force-ltr text-right text-body-2 font-weight-medium"
                                style="font-size:12px;"
                            >
                                {{item.symbol}}{{format_currency_value(item.value).unit}} = ￦{{format_currency_value(item.value).integer}}<span style="font-weight:400; color:#888;">{{format_currency_value(item.value).decimal}}</span>
                            </div>
                            <div
                                v-if="item.type == 'coin'"
                                class="mb-0 force-ltr text-right text-body-2 font-weight-medium"
                                style="font-size:12px;"
                            >
                                <span v-if="item.symbol">1{{item.symbol}} =</span>￦{{format_coin_value(item.value).integer}}<span style="font-weight:400; color:#888;">{{format_coin_value(item.value).decimal}}</span>
                            </div>
                             -->

                            <div
                                v-if="$store.state.language == '한국어'"
                            >
                                <div
                                    v-if="item.type == 'currency'"
                                >
                                    <div
                                        v-if="item.code != exchange_list[0].code"
                                        class="force-ltr text-right text-body-2 mb-0" style="color:#888;"
                                    >
                                        <span class="mr-2px">{{exchange_list[0].symbol}}</span>
                                        <span>{{format_currency_value(item.value).integer}}</span>
                                        <span style="font-weight:400; color:#888;">{{format_currency_value(item.value).decimal}}</span>
                                    </div>
                                </div>
                                <div
                                    v-if="item.type == 'coin'"
                                >
                                    <div
                                        v-if="item.code != exchange_list[0].code"
                                        class="force-ltr text-right text-body-2 mb-0" style="color:#888;"
                                    >
                                        <span v-if="item.symbol" class="font-weight-medium mr-2px">1{{item.symbol}}</span>
                                        <span v-if="item.symbol" class="mx-1">=</span>
                                        <span class="font-weight-medium mr-2px">{{original_list[0].symbol}}</span>
                                        <span>{{format_coin_value2(item.value).integer}}</span>
                                        <span style="font-weight:400; color:#888;">{{format_coin_value2(item.value).decimal}}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="$store.state.language == 'English'"
                            >
                                <div
                                    v-if="item.type == 'currency'"
                                >
                                    <div
                                        v-if="item.code != exchange_list[0].code"
                                        class="force-ltr text-right text-body-2 mb-0" style="color:#888;"
                                    >
                                        <span class="font-weight-medium mr-2px">{{list[0].symbol}}</span>
                                        <span>{{format_currency_value2(item.value).unit}}</span>
                                        <span class="mx-1">=</span>
                                        <span class="font-weight-medium mr-2px">{{item.symbol}}</span>
                                        <span>{{format_currency_value2(item.value).integer}}</span>
                                        <span>{{format_currency_value2(item.value).decimal}}</span>


                                        <!-- <span class="mr-2px">{{exchange_list[0].symbol}}</span>
                                        <span>{{format_currency_value2(item.value).integer}}</span>
                                        <span>{{format_currency_value2(item.value).decimal}}</span> -->
                                    </div>
                                </div>
                                <div
                                    v-if="item.type == 'coin'"
                                >
                                    <div
                                        v-if="item.code != exchange_list[0].code"
                                        class="force-ltr text-right text-body-2 mb-0" style="color:#888;"
                                    >
                                        <span v-if="item.symbol" class="font-weight-medium mr-2px">1{{item.symbol}}</span>
                                        <span v-if="item.symbol" class="mx-1">=</span>
                                        <span class="font-weight-medium mr-2px">{{original_list[0].symbol}}</span>
                                        <span>{{format_coin_value2(item.value).integer}}</span>
                                        <span style="font-weight:400; color:#888;">{{format_coin_value2(item.value).decimal}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 즐겨찾기 -->
                        <div
                            class="ml-1"
                        >
                            <div
                                v-if="$store.state.client_user.is_logined"
                            >
                                <v-icon v-ripple="false" v-if="!item.is_bookmarked" size="20" @click="handleBookmark(item, true, $event)">mdi-star-outline</v-icon>
                                <v-icon v-ripple="false" v-else size="20" color="primary" @click="handleBookmark(item, false, $event)">mdi-star</v-icon>
                            </div>
                            <div
                                v-else
                            >
                                <v-icon v-ripple="false" size="20" @click.stop="not_logined()">mdi-star-outline</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>

        <!-- <v-icon
            size="36"
            class="d-flex mx-auto mt-6"
            color="alter_grey lighten-2"
        >
            mdi-cancel
        </v-icon>
        <p
            class="text-caption alter_grey--text text-center mt-2"
        >
            암호화폐 목록이 없습니다
        </p> -->
    </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    props: [
        "dialog_type",
        "original_currency",
        "exchange_list"
    ],
    components: {
        draggable,
    },

    data: () => ({
        select_list: {
            translate: [
                "KR 한국어",
                "EN 영어"
            ]
        },

        drag: false,
        list: [],
        search_list: [],
        bookmark_list: [],

        translate: "KR 한국어",
        list_type: "통화",

        oneList: [],
        original_list: [],

        keyword: "",
        is_search: false,

        is_exchange : true,
        is_custom : true,

        initialLoadCount: 20,
        isLoadingMore: false,
        fullSearchList: [], // 전체 검색 결과를 임시 저장할 변수
        loading: false
    }),

    computed: {
        scrollBarWidth() {
            return window.innerWidth - document.documentElement.clientWidth
        },

        filteredList() {
            const targetList = this.is_search ? this.search_list : this.list

            let filtered = targetList.map(item => ({
                ...item,
                calculatedValue: this.getCalculatedValue(item)
            }))

            // 스크롤을 맨 위로 이동
            this.$nextTick(() => {
                this.$refs.scrollableList.scrollTop = 0
            })
            
            if (this.is_exchange && this.is_custom) {
                return filtered
            } else if (this.is_exchange) {
                return filtered.filter(item => item.exchange_type === "현재 환율")
            } else if (this.is_custom) {
                return filtered.filter(item => item.exchange_type === "사용자지정 환율") 
            } else {
                return filtered.filter(item => false)
            }
        },

        currentResult() {
            return this.$store.state.result || 0
        }
    },

    watch: {
        keyword(){
            if(this.keyword == ""){
                this.is_search = false
            }
        },

        is_exchange(){
            if(this.list_type == "즐겨찾기"){
                this.list = tempList.filter(e => this.bookmark_list.includes(e.name_kr) && e.exchange_type == "현재 환율")
            }
        },
    },

    async mounted() {
        // # 통화 목록 불러오기
        await this.loadCurrencyList()

        await this.loadBookmark()
    },

    methods: {
        format_coin_value(value) {
            if (!value) return { integer: '0', decimal: '' }
            
            // 절대값으로 변환하여 자릿수 확인
            const absValue = Math.abs(value)

            // 정수가 0인 경우 특별 처리
            if (absValue < 1) {
                const formattedValue = value.toFixed(6)
                return {
                    integer: formattedValue,
                    decimal: ''
                }
            }
            
            // 소수점 자릿수 결정
            let decimals
            if (absValue >= 1000) {
                decimals = 0
            } else if (absValue >= 1) {
                decimals = 2
            } else {
                decimals = 6
            }
            
            // 숫자 포맷팅
            const formattedValue = value.toFixed(decimals)
            const [integerPart, decimalPart] = formattedValue.split('.')
            
            return {
                integer: this.$toComma(integerPart),
                decimal: decimalPart ? `.${decimalPart}` : ''
            }
        },

        format_coin_value2(value) {
            if (!value) return { integer: '0', decimal: '' }

            value = this.$math.divide(value, this.exchange_list[0].value)
            
            // 절대값으로 변환하여 자릿수 확인
            const absValue = Math.abs(value)

            // 정수가 0인 경우 특별 처리
            if (absValue < 1) {
                const formattedValue = value.toFixed(6)
                return {
                    integer: formattedValue,
                    decimal: ''
                }
            }
            
            // 소수점 자릿수 결정
            let decimals
            if (absValue >= 1000) {
                decimals = 0
            } else if (absValue >= 1) {
                decimals = 2
            } else {
                decimals = 6
            }
            
            // 숫자 포맷팅
            const formattedValue = value.toFixed(decimals)
            const [integerPart, decimalPart] = formattedValue.split('.')
            
            return {
                integer: this.$toComma(integerPart),
                decimal: decimalPart ? `.${decimalPart}` : ''
            }
        },

        format_currency_value(value) {
            if (!value) return { integer: '0', decimal: '' }
    
            // 절대값으로 변환하여 자릿수 확인
            const absValue = Math.abs(value)
            
            // 10 미만일 경우 100을 곱함
            const adjustedValue = absValue < 10 ? value * 100 : value
            const displayUnit = absValue < 10 ? '100' : '1'
            
            // 소수점 자릿수 결정
            let decimals = 2  // 기본 소수점 자릿수를 2로 고정
            
            // 숫자 포맷팅
            const formattedValue = adjustedValue.toFixed(decimals)
            const [integerPart, decimalPart] = formattedValue.split('.')

            // # 소수점 자리수를 항상 2자리로 유지 (추가)
            const paddedDecimal = decimalPart.padEnd(2, '0')
            
            return {
                integer: this.$toComma(integerPart),
                decimal: paddedDecimal ? `.${paddedDecimal}` : '',
                unit: displayUnit
            }
        },

        format_currency_value2(value) {
            if (!value) return { integer: '0', decimal: '' }
    
            // 절대값으로 변환하여 자릿수 확인
            const absValue = Math.abs(value)
            
            // 10 미만일 경우 100을 곱함

            const adjustedValue = this.$math.divide(this.exchange_list[0].value, value)
            const displayUnit = '1'
            
            // 소수점 자릿수 결정
            let decimals = 2  // 기본 소수점 자릿수를 2로 고정
            
            // 숫자 포맷팅
            const formattedValue = adjustedValue.toFixed(decimals)
            const [integerPart, decimalPart] = formattedValue.split('.')

            // # 소수점 자리수를 항상 2자리로 유지 (추가)
            const paddedDecimal = decimalPart.padEnd(2, '0')
            
            return {
                integer: this.$toComma(integerPart),
                decimal: paddedDecimal ? `.${paddedDecimal}` : '',
                unit: displayUnit
            }
        },

        getCalculatedValue(item) {
            const value = this.$math.divide(
                this.currentResult * this.original_currency, 
                (item.exchange_type == '현재 환율' ? item.value : item.custom)
            )
            return this.formatValue(value)
        },

        formatValue(value) {
            if (!value) return { integer: '0', decimal: '' }
            
            // 절대값으로 변환
            const absValue = Math.abs(value)
            
            // 정수가 0인 경우 (1보다 작은 경우) 특별 처리
            if (absValue < 1) {
                const formattedValue = value.toFixed(6)
                return {
                    integer: formattedValue,
                    decimal: ''
                }
            }
            
            // 숫자 포맷팅 (소수점 2자리 고정)
            const formattedValue = this.$math.format(value, { notation: 'fixed', precision: 2 })
            const [integerPart, decimalPart] = formattedValue.split('.')
            
            return {
                integer: this.$toComma(integerPart),
                decimal: decimalPart ? `.${decimalPart}` : ''
            }
        },

        // # 통화 목록 불러오기
        async loadCurrencyList() {
            this.loading = true

            await this.$http.post("/api/currency/select")
            .then(async(res) => {
                let krwData, usdData, currencyData

                // # 한국 원화 데이터 생성
                krwData = {
                    exchange_type: "현재 환율",
                    custom: 0,
                    type: "currency", 
                    chosung: "ㅎㄱ",
                    name_kr: "한국",
                    name_en: "South Korea",
                    code: "KRW",
                    symbol: "￦",
                    value: 1,
                    rate: 0,
                    is_expanded: false,
                    is_bookmarked: false,
                    show_tab: "만억조",
                    show_tab_touch: false
                }

                // # 미국 달러 데이터 생성
                usdData = res.data.find(item => item.code == "USD")
                usdData = {
                    ...usdData,
                    exchange_type: "현재 환율",
                    custom: 0,
                    type: "currency",
                    is_expanded: false,
                    is_bookmarked: false,
                    show_tab: "만억조",
                    show_tab_touch: false
                }


                // 통화 데이터에 기본값 추가
                currencyData = res.data.map(item => ({
                    ...item,
                    exchange_type: "현재 환율",
                    custom: 0,
                    type: "currency",
                    is_expanded: false,
                    is_bookmarked: false,
                    show_tab: "만억조",
                    show_tab_touch: false,
                }))

                console.log(currencyData)


                // 코인 데이터 가져오기
                const coinRes = await this.$http.post("/api/coin/select")
                
                // 코인 데이터에 기본값 추가 
                const coinData = coinRes.data.map(item => ({
                    ...item,
                    exchange_type: "현재 환율", 
                    custom: 0,
                    type: "coin",
                    is_expanded: false,
                    is_bookmarked: false,
                    show_tab: "만억조",
                    show_tab_touch: false,
                }))

                // 한국 원화, 통화, 코인 데이터 병합


                // this.currencies.filter(item => item.code !== 'USD')

                if(this.$store.state.language == "한국어"){
                    // exchange_list에는 한국과 미국만 설정
                    this.original_list = [krwData, usdData, ...currencyData.filter(item => item.code != 'USD'), ...coinData]
                    this.loading = false
                }
                else if(this.$store.state.language == "English"){
                    this.original_list = [usdData, krwData, ...currencyData.filter(item => item.code != 'USD'), ...coinData]
                    // exchange_list에는 한국과 미국만 설정
                    this.loading = false
                }
            })

            // await this.$http.post("/api/currency/select")
            // .then(async(res) => {
            //     // # 한국 원화 데이터 생성
            //     const krwData = {
            //         exchange_type: "현재 환율",
            //         custom: 0,
            //         type: "currency", 
            //         chosung: "ㅎㄱ",
            //         name_kr: "한국",
            //         name_en: "South Korea",
            //         alias: "대한민국,코리아,남한,조선,고려",
            //         code: "KRW",
            //         symbol: "￦",
            //         value: 1,
            //         rate: 0,
            //         is_bookmarked: false
            //     }

            //     // 통화 데이터에 기본값 추가
            //     const currencyData = res.data.map(item => ({
            //         ...item,
            //         exchange_type: "현재 환율",
            //         custom: 0,
            //         type: "currency",
            //         is_bookmarked: false
            //     }))

            //     // 코인 데이터 가져오기
            //     const coinRes = await this.$http.post("/api/coin/select")
                
            //     // 코인 데이터에 기본값 추가 
            //     const coinData = coinRes.data.map(item => ({
            //         ...item,
            //         exchange_type: "현재 환율", 
            //         custom: 0,
            //         type: "coin",
            //         is_bookmarked: false
            //     }))

            //     // 한국 원화, 통화, 코인 데이터 병합
            //     this.original_list = [krwData, ...currencyData, ...coinData]
            //     this.loading = false

            //     this.one_list = [krwData]
            // })
        },

        getFormattedCustom(item) {
            // 숫자를 로컬 문자열로 변환하여 콤마를 포함시킵니다.
            return this.$toComma(item.custom)
        },

        updateCustom(item, value) {
            // 입력값에서 모든 콤마를 제거하고 숫자로 변환합니다.
            const numericValue = parseFloat(value.replace(/,/g, ''))
            item.custom = isNaN(numericValue) ? 0 : numericValue
        },

        // 목록 불러오기
        async load(){
            this.loading = true
            this.is_search = false
            let tempList
            tempList = this.original_list.map(item => {
                item.is_bookmarked = this.bookmark_list.includes(item.name_kr)
                return item
            }).sort((a, b) => b.is_bookmarked - a.is_bookmarked)

            if(this.list_type == "통화"){
                this.list = tempList.filter(e => e.type == "currency")
            }
            else if(this.list_type == "암호화폐"){
                this.list = tempList.filter(e => e.type == "coin")
            }
            else if(this.list_type == "즐겨찾기"){
                this.list = tempList.filter(e => this.bookmark_list.includes(e.name_kr))
                // this.list = tempList
            }
            this.loading = false
        },

        // 디바운스 적용된 검색 메서드
        debounceSearch() {
            if (!this.keyword) {
                this.is_search = false
                return
            }
            
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout)
            }
            this.searchTimeout = setTimeout(() => {
                this.search()
            }, 100) // 100ms 대기
        },

        // 검색 메서드 수정
        search() {
            this.loading = true
            let tempList = this.original_list.map(item => {
                item.is_bookmarked = this.bookmark_list.includes(item.name)
                return item
            }).sort((a, b) => b.is_bookmarked - a.is_bookmarked)

            // 검색 조건에 맞는 항목 필터링
            tempList = tempList.filter(e => 
                e.name_en.toLowerCase().includes(this.keyword)
                || e.name_kr.includes(this.keyword)
                || (e.alias ? e.alias.includes(this.keyword) : false)
                || e.chosung.includes(this.keyword)
                || e.code.toLowerCase().includes(this.keyword)
            )

            this.is_search = true

            // 탭에 따른 필터링
            if(this.list_type == "통화") {
                tempList = tempList.filter(e => e.type == "currency")
            }
            else if(this.list_type == "암호화폐") {
                tempList = tempList.filter(e => e.type == "coin")
            }
            else if(this.list_type == "즐겨찾기") {
                this.search_list = tempList.filter(e => this.bookmark_list.includes(e.name_kr))
                // tempList = tempList
            }

            // 전체 검색 결과 저장
            this.fullSearchList = tempList
            // 초기 20개만 표시
            this.search_list = tempList.slice(0, this.initialLoadCount)

            this.loading = false

            // 스크롤을 맨 위로 이동
            this.$nextTick(() => {
                this.$refs.scrollableList.scrollTop = 0
            })

            // 3초 후 나머지 검색 결과 표시
            setTimeout(() => {
                this.isLoadingMore = true
                this.search_list = this.fullSearchList
                this.isLoadingMore = false
            }, 3000)
        },

        // 즐겨찾기 불러오기
        async loadBookmark(){
            await this.$http.post("/api/bookmark/select/specific", {
                params: {
                    user_id: this.$store.state.client_user.user_id
                }
            }).then(async(res) => {
                if(res.data.length){
                    this.bookmark_list = res.data.map(e => e.name)
                }
                await this.load()
            })
        },

        handleBookmark(item, isBookmarked, event) {
            item.is_bookmarked = isBookmarked;
            if (isBookmarked) {
                this.add_bookmark(item.name_kr);
            } else {
                this.remove_bookmark(item.name_kr);
            }
            event.target.blur(); // focus 제거
        },

        // 즐겨찾기 추가
        add_bookmark(name){
            this.bookmark_list.push(name)

            this.$http.post("/api/bookmark/insert", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    name: name
                }
            }).then((res) => {
                this.loadBookmark()
                // this.change_list_type(this.list_type)
            })
        },

        // 즐겨찾기 삭제
        remove_bookmark(name){
            this.bookmark_list = this.bookmark_list.filter(e => e != name)

            this.$http.post("/api/bookmark/delete", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    name: name
                }
            }).then((res) => {
                this.loadBookmark()
                // this.change_list_type(this.list_type)
            })
        },

        // 목록 타입 변경
        async change_list_type(list_type){
            this.keyword = ""
            // if(this.keyword != ""){
            //     this.search()
            // }

            this.list_type = list_type

            // 스크롤을 맨 위로 이동
            this.$nextTick(() => {
                this.$refs.scrollableList.scrollTop = 0
            })

            if(this.list_type == "통화"){
                this.list = this.original_list.filter(e => e.type == "currency")
            }
            else if(this.list_type == "암호화폐"){
                this.list = this.original_list.filter(e => e.type == "coin")
            }
            else if(this.list_type == "즐겨찾기"){
                this.list = this.original_list.filter(e => this.bookmark_list.includes(e.name_kr))
            }
        },

        not_logined(){
            this.$globalSnackbar.show(this.$store.state.language == 'English' ? "Bookmark are available after login" : "즐겨찾기 기능은 로그인 이후 이용 가능합니다")
        },

        // ! 드래그 시작 (검토 필요)
        onStart() {
            // this.isDragging = true
        },

        // ! 드래그 종료 (검토 필요)
        onEnd() {
            // this.isDragging = false
            // this.draggedElement = null
        }
    }
}
</script>
<style scoped>
/* # 언어변경 (v-select) */
::v-deep .mobile .v-select fieldset {
    height:40px;
    min-height: 33px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px;
    height: 33px;
}

::v-deep .mobile .v-select .v-select__selections {
    height: 33px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* # 검색 (v-text-field) */
::v-deep .mobile .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px;
    height: 33px;
    background: transparent !important;
}

/* # 사용자지정 (v-text-field) */
::v-deep .custom_exchange .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .custom_exchange .v-text-field .v-text-field__slot {
    font-size: 12px;
    height: 33px;
    margin-left:-12px;
    background: transparent !important;
}

/* # 드래그 관련 */
.list-item {
    padding: 6px 0;
    border-bottom:4px;
}

.ghost {
    position: relative;
}

.ghost::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: #0369A1;
    pointer-events: none;
}

::v-deep .text-field-transparent .v-input__slot {
    background: transparent !important;
}

/* # v-checkbox */
::v-deep .v-input--selection-controls.v-input--dense .v-label {
    margin-left:-6px;
    margin-bottom:2px;
    font-size: 12px
}

/* # v-radio */
::v-deep .v-radio .v-icon {
    font-size: 18px;    
}

::v-deep .v-radio .v-label {
    font-size: 12px;
    margin-left: -6px;
}

/* v-radio 내부 패딩 조절 */
::v-deep .v-radio {
    padding: 0;
}

/* v-radio ripple 효과 위치 조절 */
::v-deep .v-radio .v-input--selection-controls__ripple {
    width:20px;
    height:20px;
    left: -5px;
    top: calc(50% - 17px);
}
</style>

<style scoped>
/* # 사용자지정2 (v-text-field) */
::v-deep .custom_exchange2 .v-text-field {
    min-height: 16px;
    height: 16px;
    padding: 0;
    border: none;
}

::v-deep .custom_exchange2 .v-text-field fieldset {
    min-height: 0;
    height: 0;
    border: none;
}

/* 모바일 화면 (600px 이하) */
@media (max-width: 600px) {
    ::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
        width: 60px;
    }
}

/* 데스크톱 화면 (600px 초과) */
@media (min-width: 601px) {
    ::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
        width: 100px;
    }
}

::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
    margin-top:2px;
    font-size: 12px;
    height: 16px;
    line-height: 16px; /* 텍스트 라인 높이 조정 */
    margin-left: -14px;
    padding: 0;
    background: transparent !important;
}

::v-deep .custom_exchange2 .v-text-field .v-label {
    display: none; /* 라벨 숨기기 */
}

::v-deep .custom_exchange2 .v-text-field .v-input__control {
    min-height: 16px;
    height: 16px;
}

::v-deep .custom_exchange2 .v-text-field .v-input__slot {
    min-height: 16px;
    height: 16px;
}

/* 사용자 지정 입력 필드 글자색 */
::v-deep .custom-text-color input {
    color: #0369A1 !important;
}
</style>

<style scoped>
/* 기존 스타일들... */

/* clearable 아이콘 스타일 조정 */
::v-deep .v-input__icon--clear {
    margin-top: -3px;  /* 위치 상하 조정 */
    /* margin-right: 4px; */
}

::v-deep .v-input__icon--clear .v-icon {
    font-size: 18px;   /* 아이콘 크기 조정 */
    color: #0369A1;
}

/* hover시 색상 변경을 원하는 경우 */
::v-deep .v-input__icon--clear .v-icon:hover {
    color: #f47f6d;    /* hover시 색상 */
}
</style>